// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/** Ionic CSS Variables **/
:root {
  /** csi **/
  --ion-color-csi: #21252C;
  --ion-color-csi-rgb: 33, 37, 44;
  --ion-color-csi-contrast: #ffffff;
  --ion-color-csi-contrast-rgb: 255, 255, 255;
  --ion-color-csi-shade: #1d2127;
  --ion-color-csi-tint: #373b41;

  /** primary **/
  --ion-color-primary: #36C1F0;
  --ion-color-primary-rgb: 54, 193, 240;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #30aad3;
  --ion-color-primary-tint: #4ac7f2;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50, 219, 100;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /*----------------------------------- Custom Colors App -----------------------------------*/
  /** white **/
  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** light white **/
  --ion-color-light-white: #f8f8f8;
  --ion-color-light-white-rgb: 248, 248, 248;
  --ion-color-light-white-contrast: #000000;
  --ion-color-light-white-contrast-rgb: 0, 0, 0;
  --ion-color-light-white-shade: #dadada;
  --ion-color-light-white-tint: #f9f9f9;

  /** light-grey **/
  --ion-color-light-grey: #D4D6E1;
  --ion-color-light-grey-rgb: 212, 214, 225;
  --ion-color-light-grey-contrast: #000000;
  --ion-color-light-grey-contrast-rgb: 0, 0, 0;
  --ion-color-light-grey-shade: #bbbcc6;
  --ion-color-light-grey-tint: #d8dae4;

  /** grey **/
  --ion-color-grey: #878A94;
  --ion-color-grey-rgb: 135, 138, 148;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #777982;
  --ion-color-grey-tint: #93969f;

  /** darkest-blue **/
  --ion-color-darkest-blue: #21252C;
  --ion-color-darkest-blue-rgb: 33, 37, 44;
  --ion-color-darkest-blue-contrast: #ffffff;
  --ion-color-darkest-blue-contrast-rgb: 255, 255, 255;
  --ion-color-darkest-blue-shade: #1d2127;
  --ion-color-darkest-blue-tint: #373b41;

  /** dark-blue **/
  --ion-color-dark-blue: #1D2229;
  --ion-color-dark-blue-rgb: 29, 34, 41;
  --ion-color-dark-blue-contrast: #ffffff;
  --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
  --ion-color-dark-blue-shade: #1a1e24;
  --ion-color-dark-blue-tint: #34383e;

  /** dark-blue **/
  --ion-color-darker-blue: #1c1f24;
  --ion-color-darker-blue-rgb: 28, 31, 36;
  --ion-color-darker-blue-contrast: #ffffff;
  --ion-color-darker-blue-contrast-rgb: 255, 255, 255;
  --ion-color-darker-blue-shade: #191b20;
  --ion-color-darker-blue-tint: #33353a;

  /** aqua **/
  --ion-color-aqua: #36C1F0;
  --ion-color-aqua-rgb: 54, 193, 240;
  --ion-color-aqua-contrast: #ffffff;
  --ion-color-aqua-contrast-rgb: 255, 255, 255;
  --ion-color-aqua-shade: #30aad3;
  --ion-color-aqua-tint: #4ac7f2;

  /** black **/
  --ion-color-black: #52565A;
  --ion-color-black-rgb: 82, 86, 90;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #484c4f;
  --ion-color-black-tint: #63676b;

  /** black-lighten **/
  --ion-color-black-lighten: #999;
  --ion-color-black-lighten-rgb: 153, 153, 153;
  --ion-color-black-lighten-contrast: #000000;
  --ion-color-black-lighten-contrast-rgb: 0, 0, 0;
  --ion-color-black-lighten-shade: #878787;
  --ion-color-black-lighten-tint: #a3a3a3;

  /** almost-white **/
  --ion-color-almost-white: #FAFAFA;
  --ion-color-almost-white-rgb: 250, 250, 250;
  --ion-color-almost-white-contrast: #000000;
  --ion-color-almost-white-contrast-rgb: 0, 0, 0;
  --ion-color-almost-white-shade: #dcdcdc;
  --ion-color-almost-white-tint: #fbfbfb;

  /** almost-grey **/
  --ion-color-almost-grey: #666;
  --ion-color-almost-grey-rgb: 102, 102, 102;
  --ion-color-almost-grey-contrast: #ffffff;
  --ion-color-almost-grey-contrast-rgb: 255, 255, 255;
  --ion-color-almost-grey-shade: #5a5a5a;
  --ion-color-almost-grey-tint: #757575;

  /** almost-black **/
  --ion-color-almost-black: #52565a;
  --ion-color-almost-black-rgb: 82, 86, 90;
  --ion-color-almost-black-contrast: #ffffff;
  --ion-color-almost-black-contrast-rgb: 255, 255, 255;
  --ion-color-almost-black-shade: #484c4f;
  --ion-color-almost-black-tint: #63676b;

  /** light-bluesky **/
  --ion-color-light-bluesky: #F6FAFF;
  --ion-color-light-bluesky-rgb: 246, 250, 255;
  --ion-color-light-bluesky-contrast: #000000;
  --ion-color-light-bluesky-contrast-rgb: 0, 0, 0;
  --ion-color-light-bluesky-shade: #d8dce0;
  --ion-color-light-bluesky-tint: #f7fbff;

  /** dark-custom **/
  --ion-color-dark-custom: #222;
  --ion-color-dark-custom-rgb: 34, 34, 34;
  --ion-color-dark-custom-contrast: #ffffff;
  --ion-color-dark-custom-contrast-rgb: 255, 255, 255;
  --ion-color-dark-custom-shade: #1e1e1e;
  --ion-color-dark-custom-tint: #383838;

  /** light-custom **/
  --ion-color-light-custom: #f4f4f4;
  --ion-color-light-custom-rgb: 244, 244, 244;
  --ion-color-light-custom-contrast: #000000;
  --ion-color-light-custom-contrast-rgb: 0, 0, 0;
  --ion-color-light-custom-shade: #d7d7d7;
  --ion-color-light-custom-tint: #f5f5f5;

  /** grey-lighten **/
  --ion-color-grey-lighten: #c5c5c5;
  --ion-color-grey-lighten-rgb: 197, 197, 197;
  --ion-color-grey-lighten-contrast: #000000;
  --ion-color-grey-lighten-contrast-rgb: 0, 0, 0;
  --ion-color-grey-lighten-shade: #adadad;
  --ion-color-grey-lighten-tint: #cbcbcb;

  /** grey-lighten **/
  --ion-color-darkest-grey: #929497;
  --ion-color-darkest-grey-rgb: 146, 148, 151;
  --ion-color-darkest-grey-contrast: #000000;
  --ion-color-darkest-grey-contrast-rgb: 0, 0, 0;
  --ion-color-darkest-grey-shade: #808285;
  --ion-color-darkest-grey-tint: #9d9fa1;

  /** blue **/
  --ion-color-blue: #1b4183;
  --ion-color-blue-rgb: 27, 65, 131;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #183973;
  --ion-color-blue-tint: #32548f;

  /** tomato **/
  --ion-color-tomato: #ff6347;
  --ion-color-tomato-rgb: 255, 99, 71;
  --ion-color-tomato-contrast: #ffffff;
  --ion-color-tomato-contrast-rgb: 255, 255, 255;
  --ion-color-tomato-shade: #e0573e;
  --ion-color-tomato-tint: #ff7359;

  /** green **/
  --ion-color-green: #008000;
  --ion-color-green-rgb: 0, 128, 0;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #007100;
  --ion-color-green-tint: #1a8d1a;

  /** dark-grey **/
  --ion-color-dark-grey: #757575;
  --ion-color-dark-grey-rgb: 117, 117, 117;
  --ion-color-dark-grey-contrast: #ffffff;
  --ion-color-dark-grey-contrast-rgb: 255, 255, 255;
  --ion-color-dark-grey-shade: #676767;
  --ion-color-dark-grey-tint: #838383;

  /** red-lighten **/
  --ion-color-red-lighten: #ff8d88;
  --ion-color-red-lighten-rgb: 255, 141, 136;
  --ion-color-red-lighten-contrast: #000000;
  --ion-color-red-lighten-contrast-rgb: 0, 0, 0;
  --ion-color-red-lighten-shade: #e07c78;
  --ion-color-red-lighten-tint: #ff9894;

  /** red-light **/
  --ion-color-red-light: #ff0002;
  --ion-color-red-light-rgb: 255, 0, 2;
  --ion-color-red-light-contrast: #ffffff;
  --ion-color-red-light-contrast-rgb: 255, 255, 255;
  --ion-color-red-light-shade: #e00002;
  --ion-color-red-light-tint: #ff1a1b;
  /*----------------------------------- Custom Colors App -----------------------------------*/

  /*----------------------------------- 2.0 Custom Colors App -----------------------------------*/
  /* Depp Black */
  --ion-color-deep-black: #000000;
  --ion-color-deep-black-rgb: 0, 0, 0;
  --ion-color-deep-black-contrast: #ffffff;
  --ion-color-deep-black-contrast-rgb: 255, 255, 255;
  --ion-color-deep-black-shade: #000000;
  --ion-color-deep-black-tint: #1a1a1a;

  /* Light Black */
  --ion-color-light-black: #212121;
  --ion-color-light-black-rgb: 33, 33, 33;
  --ion-color-light-black-contrast: #ffffff;
  --ion-color-light-black-contrast-rgb: 255, 255, 255;
  --ion-color-light-black-shade: #1d1d1d;
  --ion-color-light-black-tint: #373737;

  /* Deep Gray */
  --ion-color-deep-gray: #3c3c3c;
  --ion-color-deep-gray-rgb: 60, 60, 60;
  --ion-color-deep-gray-contrast: #ffffff;
  --ion-color-deep-gray-contrast-rgb: 255, 255, 255;
  --ion-color-deep-gray-shade: #353535;
  --ion-color-deep-gray-tint: #505050;
  
  /* Deep Gray Invertido */
  --ion-color-deep-gray-inv: #bebfc1;
	--ion-color-deep-gray-inv-rgb: 190,191,193;
	--ion-color-deep-gray-inv-contrast: #000000;
	--ion-color-deep-gray-inv-contrast-rgb: 0,0,0;
	--ion-color-deep-gray-inv-shade: #a7a8aa;
	--ion-color-deep-gray-inv-tint: #c5c5c7;

  /* Stone Gray */
  --ion-color-stone-gray: #eff0f0;
  --ion-color-stone-gray-rgb: 239, 240, 240;
  --ion-color-stone-gray-contrast: #000000;
  --ion-color-stone-gray-contrast-rgb: 0, 0, 0;
  --ion-color-stone-gray-shade: #d2d3d3;
  --ion-color-stone-gray-tint: #f1f2f2;

  /* Stone Gray Invertido */
  --ion-color-stone-gray-inv: #57595b;
	--ion-color-stone-gray-inv-rgb: 87,89,91;
	--ion-color-stone-gray-inv-contrast: #ffffff;
	--ion-color-stone-gray-inv-contrast-rgb: 255,255,255;
	--ion-color-stone-gray-inv-shade: #4d4e50;
	--ion-color-stone-gray-inv-tint: #686a6b;

  /* Gainsboro */
  --ion-color-gainsboro: #DADADA;
  --ion-color-gainsboro-rgb: 218, 218, 218;
  --ion-color-gainsboro-contrast: #000000;
  --ion-color-gainsboro-contrast-rgb: 0, 0, 0;
  --ion-color-gainsboro-shade: #c0c0c0;
  --ion-color-gainsboro-tint: #dedede;

  /* Tech Blue */
  --ion-color-tech-blue: #157FF9;
  --ion-color-tech-blue-rgb: 21, 127, 249;
  --ion-color-tech-blue-contrast: #000000;
  --ion-color-tech-blue-contrast-rgb: 0, 0, 0;
  --ion-color-tech-blue-shade: #1270db;
  --ion-color-tech-blue-tint: #2c8cfa;

  /* Synthetic Blue */
  --ion-color-synthetic-blue: #0C2A75;
  --ion-color-synthetic-blue-rgb: 12, 42, 117;
  --ion-color-synthetic-blue-contrast: #ffffff;
  --ion-color-synthetic-blue-contrast-rgb: 255, 255, 255;
  --ion-color-synthetic-blue-shade: #0b2567;
  --ion-color-synthetic-blue-tint: #243f83;

  /* Jordy Blue */
  --ion-color-jordy-blue: #8AC3F0;
  --ion-color-jordy-blue-rgb: 138, 195, 240;
  --ion-color-jordy-blue-contrast: #000000;
  --ion-color-jordy-blue-contrast-rgb: 0, 0, 0;
  --ion-color-jordy-blue-shade: #79acd3;
  --ion-color-jordy-blue-tint: #96c9f2;

  /* Purple Glow */
  --ion-color-purple-glow: #8C6FEC;
  --ion-color-purple-glow-rgb: 140, 111, 236;
  --ion-color-purple-glow-contrast: #000000;
  --ion-color-purple-glow-contrast-rgb: 0, 0, 0;
  --ion-color-purple-glow-shade: #7b62d0;
  --ion-color-purple-glow-tint: #987dee;

  /* Burnt Orange */
  --ion-color-burnt-orange: #E46E37;
  --ion-color-burnt-orange-rgb: 228, 110, 55;
  --ion-color-burnt-orange-contrast: #000000;
  --ion-color-burnt-orange-contrast-rgb: 0, 0, 0;
  --ion-color-burnt-orange-shade: #c96130;
  --ion-color-burnt-orange-tint: #e77d4b;

  /*----------------------------------- 2.0 Custom Colors App -----------------------------------*/
}

.ion-color-csi {
  --ion-color-base: var(--ion-color-csi);
  --ion-color-base-rgb: var(--ion-color-csi-rgb);
  --ion-color-contrast: var(--ion-color-csi-contrast);
  --ion-color-contrast-rgb: var(--ion-color-csi-contrast-rgb);
  --ion-color-shade: var(--ion-color-csi-shade);
  --ion-color-tint: var(--ion-color-csi-tint);
}

.ion-color-dark-custom {
  --ion-color-base: var(--ion-color-dark-custom);
  --ion-color-base-rgb: var(--ion-color-dark-custom-rgb);
  --ion-color-contrast: var(--ion-color-dark-custom-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-custom-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-custom-shade);
  --ion-color-tint: var(--ion-color-dark-custom-tint);
}

.ion-color-light-custom {
  --ion-color-base: var(--ion-color-light-custom);
  --ion-color-base-rgb: var(--ion-color-light-custom-rgb);
  --ion-color-contrast: var(--ion-color-light-custom-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-custom-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-custom-shade);
  --ion-color-tint: var(--ion-color-light-custom-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-light-white {
  --ion-color-base: var(--ion-color-light-white);
  --ion-color-base-rgb: var(--ion-color-light-white-rgb);
  --ion-color-contrast: var(--ion-color-light-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-white-shade);
  --ion-color-tint: var(--ion-color-light-white-tint);
}

.ion-color-light-grey {
  --ion-color-base: var(--ion-color-light-grey);
  --ion-color-base-rgb: var(--ion-color-light-grey-rgb);
  --ion-color-contrast: var(--ion-color-light-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-grey-shade);
  --ion-color-tint: var(--ion-color-light-grey-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-darkest-blue {
  --ion-color-base: var(--ion-color-darkest-blue);
  --ion-color-base-rgb: var(--ion-color-darkest-blue-rgb);
  --ion-color-contrast: var(--ion-color-darkest-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkest-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkest-blue-shade);
  --ion-color-tint: var(--ion-color-darkest-blue-tint);
}

.ion-color-dark-blue {
  --ion-color-base: var(--ion-color-dark-blue);
  --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-blue-shade);
  --ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-darker-blue {
  --ion-color-base: var(--ion-color-darker-blue);
  --ion-color-base-rgb: var(--ion-color-darker-blue-rgb);
  --ion-color-contrast: var(--ion-color-darker-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darker-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-darker-blue-shade);
  --ion-color-tint: var(--ion-color-darker-blue-tint);
}

.ion-color-aqua {
  --ion-color-base: var(--ion-color-aqua);
  --ion-color-base-rgb: var(--ion-color-aqua-rgb);
  --ion-color-contrast: var(--ion-color-aqua-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aqua-contrast-rgb);
  --ion-color-shade: var(--ion-color-aqua-shade);
  --ion-color-tint: var(--ion-color-aqua-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-black-lighten {
  --ion-color-base: var(--ion-color-black-lighten);
  --ion-color-base-rgb: var(--ion-color-black-lighten-rgb);
  --ion-color-contrast: var(--ion-color-black-lighten-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-lighten-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-lighten-shade);
  --ion-color-tint: var(--ion-color-black-lighten-tint);
}

.ion-color-almost-white {
  --ion-color-base: var(--ion-color-almost-white);
  --ion-color-base-rgb: var(--ion-color-almost-white-rgb);
  --ion-color-contrast: var(--ion-color-almost-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-almost-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-almost-white-shade);
  --ion-color-tint: var(--ion-color-almost-white-tint);
}

.ion-color-almost-grey {
  --ion-color-base: var(--ion-color-almost-grey);
  --ion-color-base-rgb: var(--ion-color-almost-grey-rgb);
  --ion-color-contrast: var(--ion-color-almost-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-almost-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-almost-grey-shade);
  --ion-color-tint: var(--ion-color-almost-grey-tint);
}

.ion-color-almost-black {
  --ion-color-base: var(--ion-color-almost-black);
  --ion-color-base-rgb: var(--ion-color-almost-black-rgb);
  --ion-color-contrast: var(--ion-color-almost-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-almost-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-almost-black-shade);
  --ion-color-tint: var(--ion-color-almost-black-tint);
}

.ion-color-light-bluesky {
  --ion-color-base: var(--ion-color-light-bluesky);
  --ion-color-base-rgb: var(--ion-color-light-bluesky-rgb);
  --ion-color-contrast: var(--ion-color-light-bluesky-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-bluesky-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-bluesky-shade);
  --ion-color-tint: var(--ion-color-light-bluesky-tint);
}

.ion-color-grey-lighten {
  --ion-color-base: var(--ion-color-grey-lighten);
  --ion-color-base-rgb: var(--ion-color-grey-lighten-rgb);
  --ion-color-contrast: var(--ion-color-grey-lighten-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-lighten-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-lighten-shade);
  --ion-color-tint: var(--ion-color-grey-lighten-tint);
}

.ion-color-darkest-grey {
  --ion-color-base: var(--ion-color-darkest-grey);
  --ion-color-base-rgb: var(--ion-color-darkest-grey-rgb);
  --ion-color-contrast: var(--ion-color-darkest-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkest-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkest-grey-shade);
  --ion-color-tint: var(--ion-color-darkest-grey-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-tomato {
  --ion-color-base: var(--ion-color-tomato);
  --ion-color-base-rgb: var(--ion-color-tomato-rgb);
  --ion-color-contrast: var(--ion-color-tomato-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tomato-contrast-rgb);
  --ion-color-shade: var(--ion-color-tomato-shade);
  --ion-color-tint: var(--ion-color-tomato-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-dark-grey {
  --ion-color-base: var(--ion-color-dark-grey);
  --ion-color-base-rgb: var(--ion-color-dark-grey-rgb);
  --ion-color-contrast: var(--ion-color-dark-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-grey-shade);
  --ion-color-tint: var(--ion-color-dark-grey-tint);
}

.ion-color-red-lighten {
  --ion-color-base: var(--ion-color-red-lighten);
  --ion-color-base-rgb: var(--ion-color-red-lighten-rgb);
  --ion-color-contrast: var(--ion-color-red-lighten-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-lighten-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-lighten-shade);
  --ion-color-tint: var(--ion-color-red-lighten-tint);
}

.ion-color-red-light {
  --ion-color-base: var(--ion-color-red-light);
  --ion-color-base-rgb: var(--ion-color-red-light-rgb);
  --ion-color-contrast: var(--ion-color-red-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-light-shade);
  --ion-color-tint: var(--ion-color-red-light-tint);
}

// ----------------------------------- 2.0 Custom Colors App ----------------------------------- //
.ion-color-deep-black {
  --ion-color-base: var(--ion-color-deep-black);
  --ion-color-base-rgb: var(--ion-color-deep-black-rgb);
  --ion-color-contrast: var(--ion-color-deep-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-deep-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-deep-black-shade);
  --ion-color-tint: var(--ion-color-deep-black-tint);
}

.ion-color-light-black {
  --ion-color-base: var(--ion-color-light-black);
  --ion-color-base-rgb: var(--ion-color-light-black-rgb);
  --ion-color-contrast: var(--ion-color-light-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-black-shade);
  --ion-color-tint: var(--ion-color-light-black-tint);
}

.ion-color-deep-gray {
  --ion-color-base: var(--ion-color-deep-gray);
  --ion-color-base-rgb: var(--ion-color-deep-gray-rgb);
  --ion-color-contrast: var(--ion-color-deep-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-deep-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-deep-gray-shade);
  --ion-color-tint: var(--ion-color-deep-gray-tint);
}

.ion-color-deep-gray-inv {
	--ion-color-base: var(--ion-color-deep-gray-inv);
	--ion-color-base-rgb: var(--ion-color-deep-gray-inv-rgb);
	--ion-color-contrast: var(--ion-color-deep-gray-inv-contrast);
	--ion-color-contrast-rgb: var(--ion-color-deep-gray-inv-contrast-rgb);
	--ion-color-shade: var(--ion-color-deep-gray-inv-shade);
	--ion-color-tint: var(--ion-color-deep-gray-inv-tint);
}

.ion-color-stone-gray {
  --ion-color-base: var(--ion-color-stone-gray);
  --ion-color-base-rgb: var(--ion-color-stone-gray-rgb);
  --ion-color-contrast: var(--ion-color-stone-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stone-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-stone-gray-shade);
  --ion-color-tint: var(--ion-color-stone-gray-tint);
}

.ion-color-stone-gray-inv {
  --ion-color-base: var(--ion-color-stone-gray-inv);
  --ion-color-base-rgb: var(--ion-color-stone-gray-inv-rgb);
  --ion-color-contrast: var(--ion-color-stone-gray-inv-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stone-gray-inv-contrast-rgb);
  --ion-color-shade: var(--ion-color-stone-gray-inv-shade);
  --ion-color-tint: var(--ion-color-stone-gray-inv-tint);
}

.ion-color-gainsboro {
  --ion-color-base: var(--ion-color-gainsboro);
  --ion-color-base-rgb: var(--ion-color-gainsboro-rgb);
  --ion-color-contrast: var(--ion-color-gainsboro-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gainsboro-contrast-rgb);
  --ion-color-shade: var(--ion-color-gainsboro-shade);
  --ion-color-tint: var(--ion-color-gainsboro-tint);
}

.ion-color-tech-blue {
  --ion-color-base: var(--ion-color-tech-blue);
  --ion-color-base-rgb: var(--ion-color-tech-blue-rgb);
  --ion-color-contrast: var(--ion-color-tech-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tech-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-tech-blue-shade);
  --ion-color-tint: var(--ion-color-tech-blue-tint);
}

.ion-color-synthetic-blue {
  --ion-color-base: var(--ion-color-synthetic-blue);
  --ion-color-base-rgb: var(--ion-color-synthetic-blue-rgb);
  --ion-color-contrast: var(--ion-color-synthetic-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-synthetic-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-synthetic-blue-shade);
  --ion-color-tint: var(--ion-color-synthetic-blue-tint);
}

.ion-color-jordy-blue {
  --ion-color-base: var(--ion-color-jordy-blue);
  --ion-color-base-rgb: var(--ion-color-jordy-blue-rgb);
  --ion-color-contrast: var(--ion-color-jordy-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-jordy-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-jordy-blue-shade);
  --ion-color-tint: var(--ion-color-jordy-blue-tint);
}

.ion-color-purple-glow {
  --ion-color-base: var(--ion-color-purple-glow);
  --ion-color-base-rgb: var(--ion-color-purple-glow-rgb);
  --ion-color-contrast: var(--ion-color-purple-glow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-glow-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-glow-shade);
  --ion-color-tint: var(--ion-color-purple-glow-tint);
}

.ion-color-burnt-orange {
  --ion-color-base: var(--ion-color-burnt-orange);
  --ion-color-base-rgb: var(--ion-color-burnt-orange-rgb);
  --ion-color-contrast: var(--ion-color-burnt-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-burnt-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-burnt-orange-shade);
  --ion-color-tint: var(--ion-color-burnt-orange-tint);
}

// ----------------------------------- 2.0 Custom Colors App ----------------------------------- //


// -------------------------------------------------- Necesario revisar para correspondiente update ----------------------------------------------------------------------------

// Popover - List modalExchange
$popover-md-width: 35%;
$popover-md-left: 440px !important;

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here




// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

$text-input-md-show-valid-highlight: false;

$toolbar-md-title-text-color: white;

// -------------------------------------------------- Necesario revisar para correspondiente update ----------------------------------------------------------------------------

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}