.btn {
  color         : $light-grey;
  font-size     : 14px;
  letter-spacing: .4px;
  line-height   : 20px;
  padding      : .75em 1.5em;
  text-align    : center;
  @extend %transition;
  cursor        : pointer;
  text-transform: none;
  height: 4.3rem;
}

.btn-aqua-outline {
  border: solid 1px #36C1F0;
  border-radius: 2px;
  padding: .75em 1.5em;
}


.btn-red-rounded {
  background-color: tomato;
  border-radius   : 16px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : #EEF2F8;
  letter-spacing  : .29px;
  padding         : 7px 17px;
  @extend %transition;

  &:hover {
    background-color: darken(tomato, 10%) !important;
  }
}
.btn-green-rounded {
  background-color: green;
  border-radius   : 16px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : #EEF2F8;
  letter-spacing  : .29px;
  padding         : 7px 17px;
  @extend %transition;

  &:hover {
    background-color: darken(green, 10%) !important;
  }
}

.btn-aqua-rounded {
  background-color: $aqua;
  border-radius   : 16px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : #EEF2F8;
  letter-spacing  : .29px;
  padding         : 7px 17px;
  @extend %transition;

  &:hover {
    background-color: darken($aqua, 10%);
  }
}

.btn-aqua-squared {
  background-color: $aqua;
  border-radius   : 4px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : $white;
  letter-spacing  : .29px;
  width           : 132px;
  padding         : 7px 17px;
  @extend %transition;

  &:hover {
    background-color: darken($aqua, 10%);
  }
}

.btn-rounded {
  background-color: transparent;
  border-radius   : 16px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : #52565A;
  letter-spacing  : .29px;
  padding         : 7px 17px;
  @extend %transition;

  &:hover {
    background-color: transparent;
  }
}

.btn-gray-rounded {
  background-color: $light-grey;
  border-radius   : 4px;
  font-family     : "ProximaNova-Regular";
  font-size       : 14px;
  color           : $white;
  letter-spacing  : .29px;
  width           : 213px;
  padding         : 10px 17px;
  @extend %transition;

  &:hover {
    background-color: $aqua;
  }
}

.breadcrumbs {
  cursor: pointer;
}

@media (min-width: 1px) and (max-width: 767px) {
  .btn-aqua-squared {
    width         : 100%;
    padding-top   : 13px;
    padding-bottom: 12px;
  }

}
