$white: #FFFFFF;
$light-grey: #D4D6E1;
$grey: #878A94;
$darkest-blue: #21252C;
$dark-blue: #1D2229;
$aqua: #36C1F0;
$black: #52565A;
$almost-white: #FAFAFA;
$light-bluesky: #F6FAFF;


@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

%transition {
  @include transition(all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950));
}
