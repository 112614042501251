/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* CSS Imports for Swiper JS */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/zoom";

//Marcela Ramirez
//App custom fonts

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url("./assets/fonts/ProximaNova/ProximaNova-Bold.eot");
  src: url("./assets/fonts/ProximaNova/ProximaNova-Bold.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/ProximaNova/ProximaNova-Bold.woff2") format('woff2'), url("./assets/fonts/ProximaNova/ProximaNova-Bold.woff") format('woff'), url("./assets/fonts/ProximaNova/ProximaNova-Bold.ttf") format('truetype'), url("./assets/fonts/ProximaNova/ProximaNova-Bold.svg#ProximaNova-Bold") format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url("./assets/fonts/ProximaNova/ProximaNova-Regular.eot");
  src: url("./assets/fonts/ProximaNova/ProximaNova-Regular.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/ProximaNova/ProximaNova-Regular.woff2") format('woff2'), url("./assets/fonts/ProximaNova/ProximaNova-Regular.woff") format('woff'), url("./assets/fonts/ProximaNova/ProximaNova-Regular.ttf") format('truetype'), url("./assets/fonts/ProximaNova/ProximaNova-Regular.svg#ProximaNova-Regular") format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli-Regular';
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Muli/muli-regular-webfont.eot");
  src: url("./assets/fonts/Muli/muli-regular-webfont.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/Muli/muli-regular-webfont.woff2") format('woff2'), url("./assets/fonts/Muli/muli-regular-webfont.woff") format('woff'), url("./assets/fonts/Muli/muli-regular-webfont.ttf") format('truetype'), url("./assets/fonts/Muli/muli-regular-webfont.svg#muli-regular") format('svg');
}

@font-face {
  font-family: 'Muli-Light';
  font-style: normal;
  font-weight: 100;

  src: url("./assets/fonts/Muli/muli-light-webfont.eot");
  src: url("./assets/fonts/Muli/muli-light-webfont.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/Muli/muli-light-webfont.woff2") format('woff2'), url("./assets/fonts/Muli/muli-light-webfont.woff") format('woff'), url("./assets/fonts/Muli/muli-light-webfont.ttf") format('truetype'), url("./assets/fonts/Muli/muli-light-webfont.svg#muli-light") format('svg');
}

@font-face {
  font-family: 'Soleto-Thin';
  src: url("./assets/fonts/Soleto/Soleto-Thin.eot");
  src: url("./assets/fonts/Soleto/Soleto-Thin.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/Soleto/Soleto-Thin.woff2") format('woff2'), url("./assets/fonts/Soleto/Soleto-Thin.woff") format('woff'), url("./assets/fonts/Soleto/Soleto-Thin.ttf") format('truetype'), url("./assets/fonts/Soleto/Soleto-Thin.svg#Soleto-Thin") format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Soleto-Regular';
  src: url("./assets/fonts/Soleto/Soleto-Regular.eot");
  src: url("./assets/fonts/Soleto/Soleto-Regular.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/Soleto/Soleto-Regular.woff2") format('woff2'), url("./assets/fonts/Soleto/Soleto-Regular.woff") format('woff'), url("./assets/fonts/Soleto/Soleto-Regular.ttf") format('truetype'), url("./assets/fonts/Soleto/Soleto-Regular.svg#Soleto-Regular") format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Soleto-Light';
  src: url("./assets/fonts/Soleto/Soleto-Light.eot");
  src: url("./assets/fonts/Soleto/Soleto-Light.eot?#iefix") format('embedded-opentype'), url("./assets/fonts/Soleto/Soleto-Light.woff2") format('woff2'), url("./assets/fonts/Soleto/Soleto-Light.woff") format('woff'), url("./assets/fonts/Soleto/Soleto-Light.ttf") format('truetype'), url("./assets/fonts/Soleto/Soleto-Light.svg#Soleto-Light") format('svg');
  font-weight: 300;
  font-style: normal;
}

@import "./sass/colors";
@import "./sass/utils";
@import "./sass/buttons";
@import "./sass/checkbox";

/*----------------------------------- 2.0 Custom Fonts App -----------------------------------*/
@font-face {
  font-family: 'Alliance-Bold';
  src: url("./assets/fonts/Alliance/AllianceNo1-Bold.ttf");
  src: url("./assets/fonts/Alliance/AllianceNo1-Bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Alliance-Regular';
  src: url("./assets/fonts/Alliance/AllianceNo1-Regular.ttf");
  src: url("./assets/fonts/Alliance/AllianceNo1-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Otypical-Regular';
  src: url("./assets/fonts/Otypical/OtypicalText-Regular.ttf");
  src: url("./assets/fonts/Otypical/OtypicalText-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Otypical-Medium';
  src: url("./assets/fonts/Otypical/OtypicalHeadline-Medium.ttf");
  src: url("./assets/fonts/Otypical/OtypicalHeadline-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*----------------------------------- 2.0 Custom Fonts App -----------------------------------*/



/***** Full Webpage  General Class *****/
.ion-color-bg-deep-black {
  background-color: var(--ion-color-deep-black);
}

.ion-color-bg-light-black {
  background-color: var(--ion-color-light-black);
}

.ion-color-bg-deep-gray {
  background-color: var(--ion-color-deep-gray);
}

.ion-color-bg-stone-gray {
  background-color: var(--ion-color-stone-gray);
}

.ion-color-bg-gainsboro {
  background-color: var(--ion-color-gainsboro);
}

.ion-color-bg-tech-blue {
  background-color: var(--ion-color-tech-blue);
}

.ion-color-bg-synthetic-blue {
  background-color: var(--ion-color-synthetic-blue);
}

.ion-color-bg-jordy-blue {
  background-color: var(--ion-color-jordy-blue);
}

.ion-color-bg-purple-glow {
  background-color: var(--ion-color-purple-glow);
}

.ion-color-bg-burnt-orange {
  background-color: var(--ion-color-burnt-orange);
}

.ion-color-font-deep-black {
  color: var(--ion-color-deep-black) !important;
}

.ion-color-font-stone-gray {
  color: var(--ion-color-stone-gray) !important;
}

.ion-color-font-gainsboro {
  color: var(--ion-color-gainsboro) !important;
}

.ion-color-font-tech-blue {
  color: var(--ion-color-tech-blue) !important;
}

.ion-color-font-synthetic-blue {
  color: var(--ion-color-synthetic-blue) !important;
}

.ion-color-font-jordy-blue {
  color: var(--ion-color-jordy-blue) !important;
}

.ion-color-font-purple-glow {
  color: var(--ion-color-purple-glow) !important;
}

.ion-color-font-burnt-orange {
  color: var(--ion-color-burnt-orange) !important;
}

%fix-fatty-fonts {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.item-hidden {
  visibility: hidden;
}

.preferLight {
  color-scheme: light;
}

.preferDark {
  color-scheme: dark;
}

body,
button,
html,
input,
select,
textarea {
  @extend %fix-fatty-fonts;
}

body {
  background-color: var(--ion-color-deep-black);
  min-width: 300px;
  font-size: 13px;
  line-height: 20px;
  font-family: 'Alliance-Regular';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--ion-color-aqua);
  font-family: 'Otypical-Regular';
  font-size: 49px;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 300;
}

h2 {
  font-family: "Otypical-Regular";
  font-size: 30px;
  color: var(--ion-color-aqua);
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 300;
}

h3 {
  font-family: 'Roboto-Regular';
  font-size: 22px;
  color: #FFFFFF;
  letter-spacing: .2px;
  line-height: 25px;
  font-weight: 300;
}

h4 {
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: #D4D6E1;
  letter-spacing: .4px;
  line-height: 20px;
  font-weight: 300;
}

a {
  font-family: 'Roboto-Regular';
  font-size: 14px;
  color: var(--ion-color-light-grey);
  letter-spacing: .4px;
  line-height: 20px;
  font-weight: 300;
  word-break: break-all;
}

a {
  text-decoration: none;

  &:focus,
  &:hover {
    color: var(--ion-color-light-grey);
    text-decoration: none;
  }
}

p {
  font-family: 'Alliance-Regular';
  font-size: 13px;
  color: var(--ion-color-white);
  letter-spacing: .27px;
  line-height: 20px;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

label {
  font-family: 'Soleto-Light';
  font-size: 14px;
  color: var(--ion-color-light-grey);
  letter-spacing: .4px;
  line-height: 20px;
}

.dashboard-rm-scrollbars {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.ion-font-proxima-nova-bold {
  font-family: 'ProximaNova-Bold';
}

.ion-font-proxima-nova-regular {
  font-family: 'ProximaNova-Regular';
}

.ion-font-muli-regular {
  font-family: 'Muli-Regular';
}

.ion-font-muli-light {
  font-family: 'Muli-Light';
}

.ion-font-soleto-thin {
  font-family: 'Soleto-Thin';
}

.ion-font-soleto-regular {
  font-family: 'Soleto-Regular';
}

.ion-font-soleto-light {
  font-family: 'Soleto-Light';
}

/* 2.0 Custom Fonts Class */
.ion-font-alliance-bold {
  font-family: 'Alliance-Bold' !important;
}

.ion-font-alliance-regular {
  font-family: 'Alliance-Regular' !important;
}

.ion-font-otypical-regular {
  font-family: 'Otypical-Regular' !important;
}

.ion-font-otypical-medium {
  font-family: 'Otypical-Medium' !important;
}

.ion-font-roboto-regular {
  font-family: 'Roboto-Regular' !important;
}

/* 2.0 Custom Fonts Class */

@for $size from 8 through 36 {
  .ion-font-size-#{$size} {
    font-size: #{$size}#{"px"};
  }
}

.ion-font-bold {
  font-weight: bold;
}

button,
input {
  background: none;
  outline: 0;
  border: 0;
  padding: 0;
  font-family: 'Soleto-Light';
  font-size: 14px;
  color: var(--ion-color-aqua);
  letter-spacing: .4px;
  line-height: 20px;
  font-weight: 300;
}

input {
  border-radius: 0;
  @extend %transition;
}

tr:hover .icon-base-selected-color {
  background-color: #F6FAFF !important;
}

.splash-logo {
  width: 100px;
}

tbody:before {
  /* This doesn't work because of border-collapse */
  line-height: 1em;
  content: "_";
  color: transparent;
  display: block;
}

ion-content::part(background) {
  height: 100vh;
  // background: linear-gradient(-234deg, #21252C 0%, #1B1E24 41%, #1B1E24 42%, #1A1E24 43%, #15181D 79%);
  background: var(--ion-color-light-black);

}

ion-content.splashscreen-bg::part(background) {
  height: 100vh;
  // background: #20222B;
  background: var(--ion-color-deep-black);
}

.no-ripple {
  --ripple-color: transparent !important;
}

/***** Scroll General Class *****/
ion-content::part(scroll) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

ion-content.selectable-modal::part(scroll) {
  padding-top: 0 !important;
}

/***** Scroll General Class *****/

/***** Full Webpage  General Class *****/

/***** AGM Map General Class *****/
button.gm-fullscreen-control {
  display: none !important;
}

agm-map.full-map-container {
  .agm-map-container-inner {
    // position: static !important;
    overflow: visible !important;
  }
}

.login-wrap {

  .login {

    .logo-wrap-content {
      content: url('assets/imgs/logo/VPX-lockup/Quantum-VPX-Lockup.png');
    }
  }
}

/***** AGM Map General Class *****/

/***** Overlay Element General Class *****/
#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

/***** Overlay Element General Class *****/

/***** Padding General Class *****/
.ion-no-padding-top {
  padding-top: 0;
}

.ion-no-padding-bottom {
  padding-bottom: 0;
}

.ion-no-padding-start {
  padding-left: 0;
}

.ion-no-padding-end {
  padding-right: 0;
}

@for $size from 1 through 96 {
  .ion-padding-#{$size} {
    padding: #{$size}#{"px"};
  }

  .ion-padding-top-#{$size} {
    padding-top: #{$size}#{"px"};
  }

  .ion-padding-bottom-#{$size} {
    padding-bottom: #{$size}#{"px"};
  }

  .ion-padding-start-#{$size} {
    padding-left: #{$size}#{"px"};
  }

  .ion-padding-end-#{$size} {
    padding-right: #{$size}#{"px"};
  }
}

/***** Padding General Class *****/

/***** Margin General Class *****/
.ion-no-margin-top {
  margin-top: 0;
}

.ion-no-margin-bottom {
  margin-bottom: 0;
}

.ion-no-margin-start {
  margin-left: 0;
}

.ion-no-margin-end {
  margin-right: 0;
}

@for $size from 1 through 96 {
  .ion-margin-#{$size} {
    margin: #{$size}#{"px"};
  }

  .ion-margin-top-#{$size} {
    margin-top: #{$size}#{"px"};
  }

  .ion-margin-bottom-#{$size} {
    margin-bottom: #{$size}#{"px"};
  }

  .ion-margin-start-#{$size} {
    margin-left: #{$size}#{"px"};
  }

  .ion-margin-end-#{$size} {
    margin-right: #{$size}#{"px"};
  }
}

@for $size from 1 through 100 {
  .ion-margin-x#{$size} {
    margin: #{$size}#{"%"};
  }

  .ion-margin-top-x#{$size} {
    margin-top: #{$size}#{"%"};
  }

  .ion-margin-bottom-x#{$size} {
    margin-bottom: #{$size}#{"%"};
  }

  .ion-margin-start-x#{$size} {
    margin-left: #{$size}#{"%"};
  }

  .ion-margin-end-x#{$size} {
    margin-right: #{$size}#{"%"};
  }
}

/***** Margin General Class *****/

/***** Border General Class *****/
.ion-border-custom-color {
  border-radius: 5px;
  border: 1px solid var(--ion-color-deep-gray-inv);
}

.ion-border-top {
  border-top: 1px solid var(--ion-color-deep-gray-inv);
}

.ion-border-bottom {
  border-bottom: 1px solid var(--ion-color-deep-gray-inv);
}

.ion-border-start {
  border-left: 1px solid var(--ion-color-deep-gray-inv);
}

.ion-border-end {
  border-right: 1px solid var(--ion-color-deep-gray-inv);
}

.ion-border-top-black {
  border-top: 1px solid var(--ion-color-deep-black);
}

.ion-border-bottom-black {
  border-bottom: 1px solid var(--ion-color-deep-black);
}

.ion-border-start-black {
  border-left: 1px solid var(--ion-color-deep-black);
}

.ion-border-end-black {
  border-right: 1px solid var(--ion-color-deep-black);
}

.ion-border-top-stone-gray {
  border-top: 1px solid var(--ion-color-stone-gray) !important;
}

.ion-border-bottom-stone-gray {
  border-bottom: 1px solid var(--ion-color-stone-gray) !important;
}

.ion-border-start-stone-gray {
  border-left: 1px solid var(--ion-color-stone-gray) !important;
}

.ion-border-end-stone-gray {
  border-right: 1px solid var(--ion-color-stone-gray) !important;
}

/***** Border General Class *****/

/***** Text General Class *****/
.ion-text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.ion-text-clickeable {
  cursor: pointer;
}

/***** Text General Class *****/

/***** Button General Class *****/
ion-button {
  --background-hover: white;
}

ion-button.btn-popover-mobile::part(native) {
  padding: 0 0.69rem;
}

ion-button.btn-popover::part(native) {
  padding: 0 1rem;
}

ion-button.btn-popover-3-4::part(native) {
  padding: 0 0.75rem;
}

ion-button.btn-popover-middle::part(native) {
  padding: 0 0.5rem;
}

ion-button.btn-navbar-popover::part(native) {
  padding: 0 0.5rem;
}

ion-button.btn-round {
  font-family: 'ProximaNova-Regular';
  font-size: 14px;
  letter-spacing: .29px;
  --background-hover: darken(var(--ion-color-aqua), 10%);
  @extend %transition;
}

ion-button.config-btn {
  height: 3em;
  width: 130px;
}

ion-button.consulta-cd-btn {
  font-family: 'Otypical-Regular';
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}

ion-button.filter-mobile-btn {
  font-family: 'Otypical-Regular';
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.29px;
}

ion-button.config-btn-round {
  @extend .config-btn;
  --border-radius: 16px;
}

.buttons-container {
  ion-col {

    ion-button,
    button {
      font-size: 0.7rem;
    }
  }
}

@media (min-width: 992px) {
  .buttons-container {
    ion-col {
      -webkit-flex: 0 0 calc(100% / 7) !important;
      -ms-flex: 0 0 calc(100% / 7) !important;
      flex: 0 0 calc(100% / 7) !important;
      width: calc(100% / 7) !important;
      max-width: unset !important;

      ion-button,
      button {
        font-size: 0.525rem;
        height: 1.625rem;
      }
    }
  }
}

/***** Button General Class *****/

/***** Ion-Input General Class *****/
ion-item.item-has-focus::part(native) {
  border-bottom-color: var(--ion-color-tech-blue) !important;
  caret-color: var(--ion-color-tech-blue);

  .item-highlight {
    border-color: var(--ion-color-tech-blue) !important;
    caret-color: var(--ion-color-tech-blue);
  }
}

.border-ion-input {
  border-radius: 8px !important;
  border: 1px solid var(--ion-color-stone-gray);

  input {
    font-family: 'Alliance-Regular';
    padding: 12px 8px 13px 16px;
    margin: 0 !important;
    color: black;
    font-weight: bold;
    font-size: inherit;
  }
}

.config-margin-input {
  margin: 13px 8px 23px;
}

ion-input.input-underline {
  --placeholder-font-style: "Alliance-Regular";
  --placeholder-color: var(--ion-color-tech-blue);

  input {
    placeholder-color: var(--ion-color-tech-blue);
    placeholder-font-style: "Alliance-Regular";
  }
}

/***** Ion-Input General Class *****/

/***** Popover General Class *****/
ion-popover.navbar-filters-popover::part(content) {
  background-color: var(--ion-color-gainsboro);
  width: 50%;
  top: 75px !important;
}

ion-popover.user-settings-popover::part(content) {
  background-color: var(--ion-color-gainsboro);
  box-shadow: -1px 5px 5px rgba(226, 221, 214, 0.10);
  width: 360px;
}

ion-popover.search-vin-popover::part(content) {
  width: 80%;
  top: 75px;
  left: 30px;
  bottom: 70px
}

ion-popover.list-popover::part(content) {
  top: 34.5px;
  left: 468px;
  transform-origin: left top;
  transform: scale(1);
  width: 35%;
  height: 90%;

}

navigate-popover,
cell-popover,
detail-popover {
  .m-popover-list {
    ion-item {
      font-size: 0.875rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  ion-popover.mobile-list-popover::part(content) {
    width: 80vw !important;
    left: 10vw !important;
  }
}

@media (min-width: 992px) {

  ion-popover.detail-popover-alertas::part(content),
  ion-popover.detail-popover-class::part(content) {
    width: 200px;
  }
}

/***** Popover General Class *****/

/***** ion-toast General Class *****/
ion-toast.m-custom-alert {
  z-index: 10000;
  color: red;
  background-color: red;
}

ion-toast.m-custom-toast {
  width: 90vw;
  white-space: wrap;
}

/***** ion-toast General Class *****/

/***** ion-menu Element General Class *****/
ion-menu {
  .menu-inner {
    width: 243px;
  }

  .menu-sidebar {
    position: fixed;
    z-index: 3;
    height: 100vh;
    background: linear-gradient(-234deg, #21252C 0%, #15181D 79%);
    color: var(--ion-color-light-grey);

    .close {
      --background-hover: transparent;
      margin-right: 4px;
      position: absolute;
      right: 0;

      img {
        width: 19px;
      }
    }

    .logout {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;

      a {
        display: block;
        padding-left: 47px;
        padding-top: 25px;
        padding-bottom: 25px;

        svg {
          display: inline-block;
          margin: 0 auto;
          margin-right: 17px;
        }

        // &:hover {
        //     svg g {
        //         fill: var(--ion-color-aqua);
        //     }
        //     span {
        //         color: var(--ion-color-aqua);
        //     }
        // }
        // &:focus {
        //     border-top: solid 1px #111316;
        //     box-shadow: 0px 1px #555D68;
        //     svg g {
        //         fill: var(--ion-color-aqua);
        //     }
        // }
        span {
          vertical-align: super;
        }
      }
    }

    .list-md .item-block .item-inner {
      border-bottom: none;
    }

    ion-list {
      margin-top: 101px;

      ion-label {
        font-size: 14px;
      }

      button {
        color: white;
        padding-left: 40px;
        padding-top: 0;
        padding-bottom: 0;

        &.active {
          background-color: #929497 !important;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }

        ion-icon {
          color: var(--ion-color-white);
          min-width: unset;
          min-height: unset;
        }

        &.item-md {
          background-color: transparent;

          ion-icon[item-start]+.item-inner {
            margin-left: 9px;
          }

          &.activated {
            background-color: transparent;
          }
        }

        // &:hover {
        //     color: var(--ion-color-aqua);
        //     ion-icon {
        //         color: var(--ion-color-aqua);
        //     }
        // }
        // &:focus {
        //     border-top: solid 1px #111316;
        //     box-shadow: 0px 1px #555D68;
        //     color: var(--ion-color-aqua);
        //     ion-icon {
        //         color: var(--ion-color-aqua);
        //     }
        // }
      }
    }
  }
}

/***** ion-menu Element General Class *****/

/***** ion-item Element General Class *****/
ion-item {
  --highlight-color-focused: var(--ion-color-tech-blue);
  --highlight-color-valid: var(--ion-color-tech-blue);
}

ion-item.comment-list {
  --color-focused: var(--ion-color-grey);
  --highlight-color-focused: var(--ion-color-grey);
  --highlight-color-valid: var(--ion-color-grey);
}

ion-item.config-item::part(native) {
  padding: 16px;
}

ion-item.config-item-tb::part(native) {
  padding-top: 16px;
  padding-bottom: 16px;
}

ion-item.config-item-tb-half::part(native) {
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-item.user-settings-item {
  ion-label {
    font-size: 14px !important;
  }
}

ion-item.menu-lat-header-logo::part(native) {
  padding-inline-start: 0 !important;
}

/***** ion-item Element General Class (dentro del modal del detalle del operador) *****/
ion-item.csi-operador-detail-map-item::part(native) {
  padding-left: 10px;
}

.disabled-label {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}

.disabled-select {
  opacity: 0.4;
}

/***** ion-item Element General Class (dentro del modal del detalle del operador) *****/

/***** ion-item Element General Class *****/

/***** ion-selectable Element General Class *****/
ion-select.dashboard-bsc-select {
  font-family: 'Alliance-Regular';
  font-weight: 700;
  font-size: 11px;
  color: var(--ion-color-deep-black);
}

ion-select.dashboard-bsc-select::part(icon) {
  color: var(--ion-color-deep-black);
  opacity: 1;
}

ion-select.dashboard-bsc-select::part(placeholder) {
  color: var(--ion-color-deep-black);
  opacity: 1;
  font-family: 'Alliance-Regular';
  font-weight: bold;
  font-size: 11px;
}

ion-select.dashboard-bsc-select::part(text) {
  color: var(--ion-color-deep-black);
  opacity: 1;
  font-family: 'Alliance-Regular';
  font-weight: bold;
  font-size: 11px;
}

ion-select.dashboard-bsc-sunus-select::part(icon) {
  color: var(--ion-color-deep-black) !important;
  opacity: 1;
}

ion-select.dashboard-bsc-sunus-select::part(placeholder) {
  color: var(--ion-color-deep-black) !important;
  opacity: 1;
}

ion-select.dashboard-bsc-sunus-select::part(text) {
  color: var(--ion-color-deep-black) !important;
  opacity: 1;
}

ion-select.map-tracking-filter {
  font-family: 'Alliance-Regular';
  font-weight: 700;
  font-size: 11px;
  color: var(--ion-color-deep-black);
}

ion-select.map-tracking-filter::part(icon) {
  color: var(--ion-color-tech-blue);
  opacity: 1;
}

ion-select.map-tracking-filter::part(placeholder) {
  color: var(--ion-color-deep-black);
  opacity: 1;
  font-family: 'Alliance-Regular';
  font-weight: bold;
  font-size: 11px;
}

ion-select.map-tracking-filter::part(text) {
  color: var(--ion-color-deep-black);
  opacity: 1;
  font-family: 'Alliance-Regular';
  font-weight: bold;
  font-size: 11px;
}

.ionic-selectable-icon {
  height: 46px;
}

/***** ion-selectable Element General Class *****/

/***** ion-breadcrumb Element General Class *****/
ion-breadcrumb::part(native) {
  padding: 0;
}

/***** ion-breadcrumb Element General Class *****/

/***** ion-alert Element General Class *****/
ion-alert.base-alert .alert-wrapper,
ion-alert.select-alert .alert-wrapper,
ion-alert.dashboard-bsc-select-alert .alert-wrapper {
  background: var(--ion-color-gainsboro);

  .alert-head {
    .alert-title {
      font-size: 1.375rem;
      color: var(--ion-color-deep-black);
      font-family: 'Alliance-Regular';
      font-weight: 300;
    }

    .alert-sub-title {}
  }

  .alert-message {
    font-size: 0.938rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .alert-button-group {
    .alert-button {
      color: var(--ion-color-deep-black);
      font-family: 'Alliance-Regular';

      ion-ripple-effect {
        color: transparent;
      }
    }
  }

  .alert-checkbox-group {
    .alert-checkbox {
      .alert-button-inner {

        .alert-checkbox-icon {
          background-color: var(--ion-color-tech-blue);
          border-color: var(--ion-color-tech-blue);
        }

        .alert-checkbox-label {
          font-family: 'Alliance-Regular';
        }

      }
    }
  }

  .alert-radio-group {
    border-color: var(--ion-color-deep-gray-inv);

    .alert-radio {
      .alert-button-inner {
        .alert-radio-icon {
          // background-color: var(--ion-color-tech-blue);
          border-color: var(--ion-color-tech-blue);
          color: var(--ion-color-tech-blue);

          .alert-radio-inner {
            background-color: var(--ion-color-tech-blue);
          }
        }

        .alert-radio-label {
          font-family: "Alliance-Regular";
          padding-left: 42px;
          padding-right: 16px;
          font-size: 14px;
        }
      }
    }
  }
}

ion-alert.dashboards-alert .alert-wrapper {
  .alert-message {
    overflow: auto !important;
  }
}

ion-alert.error-alert .alert-wrapper {
  .alert-message {
    overflow-y: auto !important;
  }
}

ion-alert.dashboard-bsc-select-alert .alert-wrapper {
  --width: 450px;
  --max-width: 500px;
}

ion-alert.dashboard-amdf-alert .alert-wrapper {
  --min-width: 500px;
  --width: auto;
  --min-height: auto;
  --height: auto;
  --max-height: 90%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  font-family: var(--ion-font-family, inherit);
  contain: strict;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1001;
}

/***** ion-alert Element General Class *****/

/***** Header Element General Class *****/
header {
  .menu img {
    width: 27px;
  }

  width: 100%;
  height: 70px;
  z-index: 2;
  position: fixed;
  background-color: var(--ion-color-gainsboro);

  .navbar-header-right.mobile-header-actions {
    // padding-right: 10px;
  }

  .grid {
    padding: 0;

    .input-underline {
      margin-top: 20px;
    }

    .row {
      .col {
        padding: 0 15px 0 15px;

        button {
          ion-icon {
            width: 22px !important;
            height: 21px !important;
            color: var(--ion-color-white);
          }
        }
      }
    }
  }

  .item-md {
    padding: 0;
    background-color: transparent;

    .item-inner {
      padding: 0 !important;
    }
  }

  .input-md {
    width: 100%;
  }

  .text-input-md {
    margin: 0;
    width: 100%;
  }

  .mobile-header-text {
    display: inline-block;
    margin-left: 16px;
    font-size: 1.9em;
    vertical-align: middle;
    font-family: "Soleto-Thin";
  }

  .header-logo-text {
    margin-left: 85px;
    margin-top: 25px;
    font-family: "Soleto-Thin";
    font-size: 1.5em;
  }

  .logo-csi {
    width: 60%;
    // width: 96px;
    max-width: none;
    margin-top: 17px;
  }

  .logo-csi-desktop {
    margin-left: 300px;

    &.not-static-menu {
      margin-left: 85px !important;
    }
  }

  .header-right {
    // margin-top: 13px;
    // float: right;
    // display: flex;
    // box-align: center;
    // align-items: center;

    .helper-btn {
      padding: 0;
      margin: 0;

      ion-icon {
        color: transparentize($aqua, 0.3) !important;
      }
    }

    .filters {
      padding: 0;
      margin: 0;

      ion-icon {
        color: transparentize($aqua, 0.3) !important;
      }
    }

    .notification {
      padding: 0;
      margin: 0;

      ion-icon {
        color: transparentize($aqua, 0.3) !important;
      }
    }

    .notification,
    .filters {
      /* position: relative;
          margin-right: 28px;
          padding: 1px 7px 2px 0; */

      img {
        width: 19px;
      }

      .notification-number {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 9px;
        padding: 3px 8px 3px 4px;
      }
    }

    .notification-content {
      width: 360px;
      position: fixed;
      top: 60px;
      right: 40px;
      background-color: var(--ion-color-stone-gray);
      box-shadow: -1px 0px 5px 5px rgba(29, 34, 41, 0.1);
      height: auto;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &.notification-visible {
        visibility: visible;
        opacity: 1;
      }

      .notification-title {
        background-color: var(--ion-color-deep-black);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 18px;
        padding-right: 18px;
      }

      .notification-column {
        max-height: 400px;
        height: auto;
        overflow-y: auto;
      }

      .notification-item {
        border-bottom: solid 1px var(--ion-color-gainsboro);

        a {
          display: block;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 18px;
          padding-right: 18px;
        }

        p {
          font-family: "Alliance-Regular";
          font-size: 13px;
          color: var(--ion-color-deep-black);
          letter-spacing: 0.27px;
          line-height: 20px;
        }
      }
    }

    h4 {
      display: inline-block;
    }

    .avatar {
      display: inline-block;
      width: 46px;
      margin-left: 18px;
      border-radius: 50%;
    }
  }
}

/***** Header iPad Element General Class *****/
@media (min-width: 768px) and (max-width: 991px) {
  header {
    background-color: var(--ion-color-gainsboro);
    padding-top: 14px;

    ion-grid,
    ion-row,
    ion-col {
      height: 100%;
    }

    .mobil {
      height: 95px;
      padding-top: 7px;
      // padding-top: 3px;
    }

    .menu {
      // margin-top: 0;
      // margin-bottom: 12px;

      img {
        width: 27px;
      }
    }

    .logo-csi {
      width: 40%;
      // width: 96px;
      margin-top: inherit;
      margin-left: 10px;
      max-width: none;
      position: relative;
      // top: 10px;
      // top: 19px;
    }

    ion-row.ion-align-items-custom-device {
      align-items: center !important;

      ion-col {
        height: auto !important;
      }
    }
  }

  header .navbar-header-right.mobile-header-actions {
    .notification {
      margin-right: 0 !important;
      display: inline-block;

      img {
        width: 22px;
      }
    }

    .search-wrap {
      display: inline-block;
      margin-right: 20px;
      margin-left: 0;
      position: relative;

      input {
        position: absolute;
        width: 300px;
        right: -500px;
        padding-bottom: 7px;
        color: var(--ion-color-light-grey);
        border-bottom: solid 1px var(--ion-color-grey);
        transition: 0.2s;
        max-width: initial;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--ion-color-light-grey);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--ion-color-light-grey);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--ion-color-light-grey);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--ion-color-light-grey);
        }
      }
    }
  }

  ion-popover.navbar-filters-popover::part(content) {
    width: 60%;
  }
}

@media (min-width: 992px) and (max-width: 1032px) {
  header.ion-grid-full-height {
    // background-color: #2B323E;
    padding-top: 14px;

    ion-grid,
    ion-row,
    ion-col {
      height: 100%;
    }

    .mobil {
      height: 95px;
      padding-top: 7px;
      // padding-top: 3px;
    }

    .menu {
      // margin-top: 0;
      // margin-bottom: 12px;

      img {
        width: 27px;
      }
    }

    .logo-csi {
      width: 40%;
      // width: 96px;
      margin-top: inherit;
      margin-left: 10px;
      max-width: none;
      position: relative;
      // top: 10px;
      // top: 19px;
    }

    ion-row.ion-align-items-custom-device {
      align-items: center !important;

      ion-col {
        height: auto !important;
      }
    }
  }

  header.ion-grid-full-height .navbar-header-right.mobile-header-actions {
    .notification {
      margin-right: 0 !important;
      display: inline-block;

      img {
        width: 22px;
      }
    }

    .search-wrap {
      display: inline-block;
      margin-right: 20px;
      margin-left: 0;
      position: relative;

      input {
        position: absolute;
        width: 300px;
        right: -500px;
        padding-bottom: 7px;
        color: var(--ion-color-light-grey);
        border-bottom: solid 1px var(--ion-color-grey);
        transition: 0.2s;
        max-width: initial;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--ion-color-light-grey);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--ion-color-light-grey);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--ion-color-light-grey);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--ion-color-light-grey);
        }
      }
    }
  }
}

/***** Header iPad Element General Class *****/

/***** Header Movil Element General Class *****/
@media (min-width: 1px) and (max-width: 767px) {
  header.navbar-header {
    height: 100px !important;
    padding-top: 30px;
    // min-width: 300px;

    ion-grid,
    ion-row,
    ion-col {
      height: 100%;
    }

    .mobil {
      height: 95px;
      padding-top: 3px;
    }

    .menu {
      // padding: 0 5px 0;
      margin-left: 0;
      margin-right: 0;

      img {
        width: 27px;
      }
    }

    .logo-csi {
      width: 60%;
      // width: 96px;
      margin-top: unset;
      // margin-left: 10px;
      max-width: none;
      position: relative;
      margin-left: 8px;
      // top: 14px;
    }

    .navbar-col-full-center {
      margin-top: 1%;
    }

    .navbar-header-right .notification-content {
      top: 95px !important;
      left: 50%;
      transform: translate(-50%);
      width: calc(100% - 30px);

      .notification-column {
        max-height: 300px;
      }
    }

    .notification,
    .filters {
      width: 100%;
      // margin-top: 15px !important;
    }

    ion-row.ion-align-items-custom-device {
      align-items: flex-start !important;
    }
  }

  header .navbar-header-right .mobile-header-actions {
    .notification {
      margin-right: 0 !important;
      display: inline-block;

      img {
        width: 23px;
      }
    }

    .search-wrap {
      display: inline-block;
      position: relative;
      margin-right: 20px;
      margin-left: 0;

      input {
        position: absolute;
        width: 300px;
        right: -500px;
        padding-bottom: 7px;
        color: var(--ion-color-stone-gray);
        border-bottom: solid 1px var(--ion-color-stone-gray);
        transition: 0.2s;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--ion-color-stone-gray);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--ion-color-stone-gray);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--ion-color-stone-gray);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--ion-color-stone-gray);
        }
      }
    }
  }

  ion-popover.navbar-filters-popover::part(content) {
    width: 95%;
  }
}

/***** Header Movil Element General Class *****/

/***** Header Element General Class *****/

/***** DX-Elements General Class *****/
/***** dx-table Element General Class *****/
dx-date-box {
  font-size: 0.875rem;
  font-family: 'Alliance-Regular';

  input.dx-texteditor-input {
    color: var(--ion-color-deep-black);
    font-size: 0.875rem;
    font-family: "Alliance-Regular";
  }

  .dx-dropdowneditor-icon {
    color: var(--ion-color-tech-blue);
  }
}

dx-date-box.dx-texteditor.dx-editor-outlined {
  border-radius: 0px;
  background: var(--ion-color-stone-gray);
  border-color: var(--ion-color-stone-gray);
}

dx-date-box.dx-texteditor.dx-state-hover {
  border-color: var(--ion-color-jordy-blue);
}

.ion-label-date-input {
  color: black;
  font-size: 1rem;
  font-family: 'Alliance-Regular';
}

.dx-popup-wrapper>.dx-overlay-content {
  background-color: var(--ion-color-stone-gray);
  color: var(--ion-color-deep-black);
  font-family: "Alliance-Regular";

  .dx-calendar {
    background-color: var(--ion-color-stone-gray);
  }

  .dx-calendar-body thead tr th {
    color: var(--ion-color-tech-blue);
  }

  .dx-calendar-navigator .dx-button {
    border-color: var(--ion-color-stone-gray);
  }

  .dx-button-mode-contained {
    background-color: var(--ion-color-stone-gray);
  }

  .dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
  .dx-calendar-navigator-next-month.dx-button .dx-icon,
  .dx-calendar-navigator-next-view.dx-button .dx-icon,
  .dx-calendar-navigator-previous-month.dx-button .dx-icon,
  .dx-calendar-navigator-previous-view.dx-button .dx-icon {
    color: var(--ion-color-tech-blue);
  }

  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date {
    color: var(--ion-color-deep-black);
    -webkit-box-shadow: inset 0 0 0 1px var(--ion-color-stone-gray), inset 0 0 0 1000px var(--ion-color-tech-blue);
    box-shadow: inset 0 0 0 1px var(--ion-color-stone-gray), inset 0 0 0 1000px var(--ion-color-tech-blue);
  }

  .dx-calendar-cell.dx-calendar-selected-date,
  .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today {
    -webkit-box-shadow: inset 0 0 0 1px var(--ion-color-stone-gray), inset 0 0 0 1000px var(--ion-color-tech-blue);
    box-shadow: inset 0 0 0 1px var(--ion-color-stone-gray), inset 0 0 0 1000px var(--ion-color-tech-blue);
  }
}



/***** dx-table Element General Class *****/

/***** dx-table Element General Class *****/
.dx-pivotgrid .dx-area-description-cell,
.dx-pivotgrid .dx-column-header,
.dx-pivotgrid .dx-data-header,
.dx-pivotgrid .dx-total {
  background-color: var(--ion-color-stone-gray);
}

.dx-pivotgrid .dx-area-description-cell.dx-pivotgrid-background,
.dx-pivotgrid .dx-column-header,
.dx-pivotgrid .dx-data-header {
  background-color: var(--ion-color-stone-gray);
}

.dx-pivotgrid .dx-grandtotal {
  background-color: var(--ion-color-stone-gray);
  color: var(--ion-color-deep-black);
}

.dx-pivotgrid .dx-pivotgrid-area td {
  color: var(--ion-color-deep-black);
}

dx-pivot-grid {

  *:not(.dx-icon-exportxlsx) {
    font-size: 0.625rem !important;
  }

  td,
  th {
    max-width: 100% !important;
  }

  tbody::before {
    display: none !important;
  }

  .dx-icon-xlsxfile::before {
    content: url("./assets/imgs/icons/excel20x20.png") !important;
    opacity: 0.9 !important;
  }

  .dx-icon-columnchooser::before {
    content: none !important;
    content: url("./assets/imgs/icons/filter.svg") !important;
  }

  .dx-button {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .dx-pivotgrid-fields-area.dx-area-fields {
    float: left;
  }
}

dx-data-grid {
  tbody::before {
    display: none !important;
  }

  .dx-datagrid-header-panel {
    border-left: 1px solid var(--ion-color-stone-gray);
    border-top: 1px solid var(--ion-color-stone-gray);
    border-right: 1px solid var(--ion-color-stone-gray);
    background-color: var(--ion-color-stone-gray) !important;

    .dx-toolbar {
      margin-top: 5px;
      padding-right: 5px;
      margin-bottom: 9px;
      background-color: var(--ion-color-stone-gray) !important;
    }
  }

  .dx-icon-xlsxfile::before {
    content: url("./assets/imgs/icons/excel20x20.png") !important;
    opacity: 0.9 !important;
  }
}

.dx-datagrid-rowsview .m-link {
  text-decoration: underline;
  color: var(--ion-color-tech-blue);
}

.dx-highlight-outline::after {
  border-color: rgba(255, 0, 0, 0.5) !important;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  border-radius: 0px;
  color: var(--ion-color-tech-blue);
  // background-color: var(--ion-color-gainsboro);
  background-color: var(--ion-color-stone-gray);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
}

ion-row.travel-list {

  .dx-datagrid-table {
    border-radius: 2px !important;
    margin-bottom: 10px !important;
    background-color: #fafafa !important;
    -webkit-box-shadow: 0 1px 1px rgba(29, 34, 41, 0.1) !important;
    box-shadow: 0 1px 1px rgba(29, 34, 41, 0.1) !important;
  }

  .dx-datagrid {
    background-color: transparent !important;
  }



  .dx-header-row td {
    height: 50px !important;
    font-family: "Roboto-Regular" !important;
    font-size: 14px !important;
    color: var(--ion-color-deep-black) !important;
    letter-spacing: 0.29px !important;
    line-height: 20px !important;
    text-align: center !important;
    font-weight: 300 !important;
    vertical-align: middle !important;
    border-color: transparent !important;
    padding: 0 7px !important;
    min-width: 70px !important;

    &:hover {
      background-color: var(--ion-color-deep-gray-inv);
      color: var(--ion-color-stone-gray-inv) !important;
    }
  }

  tbody::before {
    content: "" !important;
  }

  .dx-header-row {
    border-radius: 2px !important;
    margin-bottom: 10px !important;
    // background-color: var(--ion-color-gainsboro) !important;
    background-color: var(--ion-color-stone-gray) !important;
    -webkit-box-shadow: 0 1px 1px rgba(29, 34, 41, 0.1) !important;
    box-shadow: 0 1px 1px rgba(29, 34, 41, 0.1) !important;
  }

  .dx-datagrid-table tbody tr td:after {
    position: absolute !important;
    content: " " !important;
    z-index: 0 !important;
    top: 0 !important;
    //border-bottom: 1.5px solid #ffffff !important;
    left: 0 !important;
    right: 0 !important;
  }

  .dx-datagrid-table tbody tr {
    // background-color: var(--ion-color-gainsboro);
    background-color: var(--ion-color-stone-gray);

    td {
      height: 80px;
      vertical-align: middle !important;
      font-family: "Alliance-Regular";
      color: var(--ion-color-deep-black);
      letter-spacing: 0.27px;
      line-height: 20px;
      border-color: var(--ion-color-deep-black);
      position: relative;
      padding: 0 7px;
      text-align: center !important;
    }

    &:hover:not(.dx-header-row) {
      background-color: var(--ion-color-jordy-blue) !important;
      color: var(--ion-color-deep-black) !important;

      td {
        color: var(--ion-color-deep-black);
        @extend %transition;

        a {
          color: var(--ion-color-deep-black);
        }

        &:after {
          border-color: var(--ion-color-jordy-blue);
        }

        svg {
          g {
            fill: var(--ion-color-deep-black);
          }

          path {
            fill: var(--ion-color-deep-black);
          }
        }
      }

      cursor: pointer
    }
  }
}

ion-row.matriz-list {

  tbody::before {
    display: none !important;
    content: "" !important;
  }

  .dx-header-row {
    // background-color: var(--ion-color-gainsboro) !important;
    background-color: var(--ion-color-stone-gray) !important;
    color: var(--ion-color-deep-black) !important;

    td {
      border-color: var(--ion-color-gainsboro) !important;
    }

    >td>.dx-datagrid-text-content {
      font-size: 0.625rem;
    }
  }

  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers+.dx-datagrid-rowsview {
    border-top: 0px !important;
  }
}

/***** dx-table Element General Class *****/

/***** dx-date-box Element General Class *****/
.tractores-filters {
  .filters {

    .dx-placeholder,
    .ionic-selectable-value-item {
      font-family: "Alliance-Regular";
      font-size: 0.875rem;
      font-weight: 400;
    }

    .ionic-selectable-value {
      padding-left: 4px;
    }
  }
}

/***** dx-date-box Element General Class *****/

/***** dx-popup Element General Class *****/
.dx-popup-content {

  .dx-scrollview-content {
    color: #333;
    font-weight: normal;
    font-size: 0.75rem;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Verdana, sans-serif;
    line-height: 1.33334;

    .dx-list-select-all {
      padding: 4px 0;

      .dx-list-select-all-label {
        padding: 0 5px;
      }
    }

    .dx-list-item {
      border: none;

      .dx-list .dx-empty-message,
      .dx-list-item-content {
        padding: 5px;
      }
    }
  }
}

.dx-widget {
  color: var(--ion-color-deep-black);
  font-weight: normal;
  font-size: 0.75rem;
  font-family: "Alliance-Regular";
  line-height: 1.33334;
}

/***** dx-popup Element General Class *****/

/***** DX-Elements Table General Class *****/


/***** ion-modal Element General Class *****/
ion-modal.round-corners::part(content) {
  --border-radius: 15px 15px 0 0;
}

ion-modal.bottom-modal-pos::part(content) {
  position: absolute;
  bottom: 0px;
}

ion-modal.bottom-modal-pos {
  --height: 45%;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.fullscreen,
.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

/* ion-modal::part(content) {

} */

ion-content.selectable-modal::part(background) {
  background: none !important;
  background-color: var(--ion-color-gainsboro) !important;
}

ion-content.m-modal::part(background) {
  background: none !important;
  background-color: var(--ion-color-gainsboro) !important;
}

ion-content.image-viewer-modal::part(background),
ion-modal.image-viewer-modal {
  --background: rgba(26, 26, 26, 0.7) !important;
  background: rgba(26, 26, 26, 0.7) !important;
  --background-color: rgba(26, 26, 26, 0.7) !important;
  background-color: rgba(26, 26, 26, 0.7) !important;
}

.m-modal {
  background: none !important;
  background-color: var(--ion-color-gainsboro) !important;

  .m-modal-icon {
    color: var(--ion-color-tech-blue);
    background-color: transparent;
    box-shadow: none;
  }

  .m-modal-icon:hover {
    background-color: transparent !important;
  }

  .m-modal-anchor {
    text-decoration: underline;
  }

  .m-modal-header {
    .m-modal-title {
      color: var(--ion-color-tech-blue);
      font-size: 1.313rem;
    }
  }

  .m-modal-body {
    ion-scroll {
      white-space: nowrap;

      .scroll-content {
        padding: 0 !important;
      }

      ion-list {
        margin-bottom: 0 !important;
      }
    }

    .m-modal-selectable-scroll {
      height: 81vh;
    }

    .m-modal-selected-scroll {
      height: 40vh;
    }

    ion-list-header {
      font-size: 1em;
    }

    .m-modal-selectable-fields {
      ion-list-header {
        margin-bottom: 0;
      }
    }

    .m-modal-selected-fields {
      padding: 0;

      .m-modal-row-list,
      .m-modal-col-list {
        height: 100%;
        background-color: var(--ion-color-stone-gray);
      }
    }
  }

  table.table tbody tr>td {
    font-size: 0.8em !important;
  }
}

/***** ion-modal Element General Class *****/

/***** ion-slides Element General Class *****/
ion-slides.damage-slide>.swiper-pagination-bullets {
  bottom: -5px;
}

/***** ion-slides Element General Class *****/


/***** ion-virtual-scroll Element General Class *****/
/* cdk-virtual-scroll-viewport {
//display: contents !important;
} */

.virtual-scroll {
  height: 200px;
}

.virtual-scroll-500 {
  height: 500px;
}

cdk-virtual-scroll-viewport>div.cdk-virtual-scroll-content-wrapper {
  // background-color: var(--ion-color-gainsboro);
  background-color: var(--ion-color-stone-gray);
}

/***** ion-virtual-scroll Element General Class *****/

/***** Autocomplete styles -- hast be loaded before screem loads *****/
#mat-autocomplete-0 {
  top: 30rem !important;
  left: 22rem !important;
  position: relative !important;
  background-color: white !important;
}

#mat-autocomplete-1 {
  top: 30rem !important;
  left: 69.5rem !important;
  position: relative !important;
  background-color: white !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  border-left: 1px solid #1B1E24;
  border-right: 1px solid #1B1E24;
  -webkit-text-fill-color: #D4D6E1;
  -webkit-box-shadow: 0 0 0 1000px #1B1E24 inset;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 1000px #1B1E24 inset;
}

/***** Autocomplete styles -- hast be loaded before screem loads *****/

/***** Dashboard Element General Class *****/
/* .dashboard-information {
  padding-left: 0;
  padding-right: 0;
  padding-top: 50px;
} */

/* .dashboard {
  position: absolute;
  display: inline-block;
  // height: calc(100vh - 70px);
  height: calc(100vh - 100px);
  bottom: 0;
  background-color: #EEF2F8;
  width: calc(100vw - 300px);
  left: 300px;

  &.not-static-menu {
    @media (min-width: 768px) {
      width: calc(100vw - 75px);
      left: 75px;
    }
  }

  &.dashboard-full-width {
    width: 100vw !important;
    left: 0 !important;
  }
} */

/* .dashboard-content {
  // position: relative;
  overflow-x: scroll;
  height: inherit;
} */

/* .dashboard-header {
  padding: 55px 40px 40px;
  background-color: #171B1F;
  width: 100%;

  h2 {
    margin-bottom: 51px;
  }

  .welcome {
    padding: 5px 15px;
  }

  .squared-link {
    padding: 5px 15px;

    .squared-option {
      min-height: 100%;
      background-color: var(--ion-color-dark-blue);
      padding: 25px 29px 30px;
      border-radius: 4px;
      @extend %transition;

      h3 {
        margin-bottom: 19px;
        word-break: break-word;
      }

      p {
        color: var(--ion-color-white) !important;
      }
    }

    &:hover .squared-option {
      background-color: var(--ion-color-aqua);

      .icon-circle {
        background-color: #25B3E3;

        ion-icon {
          background: var(--ion-color-white);
        }

        svg {
          g {
            fill: var(--ion-color-white);
          }
        }
      }
    }
  }

  .icon-circle {
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
    border-radius: 50%;
    background-color: #2B323E;
    position: relative;
    @extend %transition;

    ion-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background: var(--ion-color-aqua);
      background: #fff;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      g {
        fill: var(--ion-color-aqua);
      }
    }
  }
} */

/* .container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
} */

/* .dashboard-information {
  background-color: #EEF2F8;
  padding: 34px 40px 30px;

  .information-header {
    margin-bottom: 30px;

    h2 {
      display: inline-block;
    }

    button {
      display: inline-block;
      width: 160px;
      margin-top: 10px;
    }

    .breadcrumbs {
      font-family: 'Soleto-Light';
      font-size: 14px;
      color: var(--ion-color-light-grey);
      letter-spacing: .4px;
      line-height: 20px;
      margin-top: 4px;
      font-weight: 300;
      word-break: break-all;

      span a {
        color: var(--ion-color-deep-black);
      }
    }

    input.input-breadcrums {
      font-family: 'Soleto-Light';
      font-size: 12px;
      color: var(--ion-color-almost-black);
      letter-spacing: .34px;
      line-height: 12px;
      margin-top: 4px;
    }
  }

  .table {
    thead {
      border-radius: 2px;
      margin-bottom: 10px;
      background-color: var(--ion-color-almost-white);
      box-shadow: 0 1px 1px rgba(29, 34, 41, 0.10);
      @extend %transition;

      tr {
        th {
          height: 50px;
          font-family: 'Muli-Regular';
          font-size: 14px;
          color: var(--ion-color-deep-black);
          letter-spacing: .29px;
          line-height: 20px;
          text-align: center;
          font-weight: 300;
          vertical-align: middle;
          border-color: transparent;
          padding: 0 7px;
          min-width: 70px;

          &:after {
            content: "";
          }

          &:focus {
            outline: -webkit-focus-ring-color auto 0;
          }

          img {
            width: 9px;
          }

          a {
            color: var(--ion-color-deep-black);
          }
        }
      }

      span.sort-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: sub;
      }

      .sorting:after,
      .sorting_asc:after,
      .sorting_asc_disabled:after,
      .sorting_desc:after,
      .sorting_desc_disabled:after {
        display: none;
      }

      .sorting span {
        background: url("./assets/imgs/icons/icon-sort.svg") no-repeat center right;
      }

      .sorting_asc span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
      }

      .sorting_desc span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
      }

      .sorting_asc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }

      .sorting_desc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }
    }

    tbody {
      box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.10);
      border-radius: 2px;

      tr {
        background-color: var(--ion-color-almost-white);
        text-align: center;
        @extend %transition;

        &:first-child {
          td {
            border-top: none;
          }
        }

        td {
          height: 80px;
          vertical-align: middle;
          font-family: 'Muli-Light';
          font-size: 13px;
          color: var(--ion-color-deep-black);
          letter-spacing: .27px;
          line-height: 20px;
          border-color: #DEDEDE;
          position: relative;
          padding: 0 7px;

          .status {
            position: absolute;
            border-radius: 2px;
            left: 0;
            width: 3px;
            height: 22px;
            background-color: var(--ion-color-aqua);
          }

          &:after {
            position: absolute;
            content: " ";
            z-index: 0;
            top: 0;
            border-bottom: 1.5px solid var(--ion-color-white);
            left: 0;
            right: 0;
            @extend %transition;
          }

          a {
            color: var(--ion-color-deep-black);
          }
        }

        &:hover {
          background-color: var(--ion-color-aqua);
          color: var(--ion-color-white);

          td {
            color: var(--ion-color-white);
            @extend %transition;

            a {
              color: var(--ion-color-white);
            }

            &:after {
              border-color: var(--ion-color-aqua);
            }

            svg {
              g {
                fill: var(--ion-color-white);
              }

              path {
                fill: var(--ion-color-white);
              }
            }
          }
        }
      }
    }
  }

  .table.without-line-th {
    tbody {
      tr {
        td {
          &:after {
            content: none;
          }
        }
      }
    }
  }
} */

/* .dashboard-information .categories button {
  margin-right: 15px;
} */

.box-white {
  background: var(--ion-color-stone-gray);
  box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.10), inset 0 1px 0 0 var(--ion-color-stone-gray);
  border-radius: 2px;
  padding: 46px 0px 35px;

  h4 {
    color: var(--ion-color-tech-blue);
  }

  h3,
  p {
    color: var(--ion-color-deep-black);
  }

  input,
  label,
  textarea {
    box-sizing: border-box;
    padding: 10px;
    font-size: 13px;
    line-height: 20px;
    border: none;
    font-family: "Alliance-Regular";
  }
}

.location-track {
  color: var(--ion-color-deep-black);

  ul {
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    margin: 10px 0;

    li {
      height: 40px;
      border-left: 1px solid var(--ion-color-gainsboro);
      padding-left: 12px;
      position: relative;

      &.active:first-child::before {
        counter-increment: step;
        content: counter(step);
        display: block;
        position: absolute;
        color: transparent;
        left: -5.5px;
        top: -4px;
        width: 8px;
        height: 8px;
        background: var(--ion-color-stone-gray);
        border: 1px solid var(--ion-color-tech-blue);
        line-height: 25px;
        font-size: 12px;
        text-align: center;
        border-radius: 100%;
      }

      &:before {
        counter-increment: step;
        content: counter(step);
        display: block;
        position: absolute;
        color: transparent;
        left: -3px;
        top: -1px;
        width: 4px;
        height: 4px;
        background: var(--ion-color-stone-gray);
        border: 1px solid var(--ion-color-gainsboro);
        line-height: 25px;
        font-size: 12px;
        text-align: center;
        border-radius: 100%;
      }

      &.active {
        border-color: var(--ion-color-tech-blue);

        &:before {
          border-color: var(--ion-color-tech-blue);
          background: var(--ion-color-tech-blue);
        }

        & span {
          color: var(--ion-color-deep-black);
        }

        &.current {
          border-color: var(--ion-color-gainsboro);
        }
      }

      span {
        position: relative;
        top: -20px;
        line-height: 40px;
        font-family: "Roboto-Regular";
        font-size: 14px;

        &+span {
          margin-left: 10px;
          font-family: "Alliance-Regular";
          font-size: 13px;
        }
      }

      &:last-child {
        border-color: transparent;

        &::before {
          height: 8px;
          width: 8px;
          left: -5.5px;
          top: -3px;
        }
      }
    }
  }
}

/***** Dashboard Element General Class *****/

.font-color-grid {
  color: var(--ion-color-deep-black);
}

.change-page-div {
  font-family: "Alliance-Regular";
  font-size: 0.875rem;

  .change-page-text {
    padding: 0 12px;
  }

  .page {
    cursor: pointer;
    text-align: center;
    padding: 0 8px;
    margin: 0 2px;
    display: inline-block;
    color: var(--ion-color-tech-blue);
    // border: 1px solid var(--ion-color-gainsboro);
    background-color: var(--ion-color-stone-gray);
    clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
  }

  .change-left {
    margin-right: 8px;
  }

  .change-right {
    margin-left: 8px;
  }

  .more-page-dots {
    padding-right: 4px;
    padding-left: 4px;
  }

  .more-page-dots.first-dots {
    padding-left: 4px;
  }

  .change-page-arrow {
    cursor: pointer;
    text-align: center;
    width: 1.6em;
    display: inline-block;
    color: var(--ion-color-deep-black);
    // border: 1px solid var(--ion-color-gainsboro);
    background-color: var(--ion-color-stone-gray);
    clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 0 100%);
  }

  padding-left: 4px;
  padding-bottom: 12px;
}

.pointerEvent {
  cursor: pointer;
}

.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.text-center {
  text-align: center;
}

.search-wrap {
  position: relative;
  margin-left: 44px;

  .input-has-focus+svg path {
    fill: var(--ion-color-aqua);
  }

  svg {
    position: absolute;
    top: 20px;
    right: 0;
  }
}

.clickable-row {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: var(--ion-color-gainsboro);
  width: 213px;

  a,
  p {
    font-family: 'Otypical-Regular';
    font-size: 14px;
    color: var(--ion-color-deep-black);
    letter-spacing: .29px;
  }

  p {
    padding-left: 22px;
  }

  .selection {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }

  .arrow-down {
    width: 9px;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 17px;
  }

  .arrow-down-icon {
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 15px;
  }

  .arrow-down {
    cursor: pointer;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  // background-color: var(--ion-color-gainsboro);
  background-color: var(--ion-color-stone-gray);
  min-width: 160px;
  z-index: 1;
  width: 100%;

  a {
    display: block;
    transition: 0s;

    &:hover:not([disabled]) {
      background-color: var(--ion-color-tech-blue);
      color: var(--ion-color-deep-black);
    }
  }

  li {
    padding: 10px 22px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: relative;

    &:hover:not([disabled]) {
      background-color: var(--ion-color-tech-blue);
      color: var(--ion-color-deep-black);
    }
  }
}

.icon-base-selected-color {
  color: var(--ion-color-tech-blue);
}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
  h1 {
    font-family: "Soleto-Thin";
    font-size: 40px;
    color: #36C1F0;
    letter-spacing: 0;
    line-height: 30px;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .splash-logo {
    width: 89px;
  }

  .login-wrap {
    width: 100%;

    .login {
      padding-top: 0;
      padding-bottom: 0;
      background-color: transparent;
      width: 100%;

      a {
        float: initial !important;
      }

      form {
        input {
          margin-bottom: 29px;
        }

        button {
          margin-top: 34px;
        }
      }

      h1 {
        margin-bottom: 25px;
      }

      p {
        margin-bottom: 38px;
      }

      .mobile-forgot-password {
        margin-top: 30px;
        display: block;
      }
    }
  }

  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #1B1E24 inset;
      -webkit-text-fill-color: #D4D6E1 !important;
      box-shadow: 0 0 0 1000px #1B1E24 inset;
    }
  }

  p {
    word-break: break-word;
  }
}

@media (min-width: 1px) and (max-width: 767px) {
  .menu-inner {
    width: 223px;
  }

  .menu-sidebar {
    width: 223px;
    overflow-y: auto;

    .logout {
      position: relative;
      margin-top: 50px;

      a {
        padding-left: 23px;
      }
    }

    .logo {
      background-color: #16191D;
      height: 70px;
      padding-top: 26px;

      img {
        width: 94px;
        display: block;
        margin: 0 auto;
      }
    }

    ion-list {
      margin-top: 61px;

      button {
        padding-left: 23px;
      }
    }
  }

  .close {
    float: right;
    /*margin-top: 50px !important;*/
    margin-right: 20px;

    img {
      width: 19px;
    }
  }
}

.tgl {
  display: none;

  // add default box-sizing for this scope
  &,
  & *,
  & *:after,
  & *:before,
  &+.tgl-btn,
  &:after,
  &:before {
    box-sizing: border-box;

    &::selection {
      background: none;
    }
  }

  +.tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked+.tgl-btn:after {
    left: 50%;
  }
}

.tgl-light {
  +.tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;

    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all .2s ease;
    }
  }

  &:checked+.tgl-btn {
    background: #9FD6AE;
  }
}

.scale-in-center.loading {
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

.monthname {
  color: var(--ion-color-darkest-blue);
}

[hidden] {
  display: none !important;
}

.scroll-content {
  overflow-y: auto !important;
}

[scrollbar-y-auto] .scroll-content {
  overflow-y: auto !important;
}

.email-wrap {
  position: relative;

  input {
    padding-right: 26px !important;
  }

  .check {
    display: none;
    position: absolute;
    right: 0;
    width: 21px;
  }
}

.ion-no-padding-tb {
  padding-top: 0px;
  padding-bottom: 0px;
}

.over-right-bottom-corner {
  right: -20px;
  bottom: -20px;
}

@media (min-width: 1px) and (max-width: 767px) {
  .over-right-bottom-corner {
    // bottom: 20px;
  }
}

.full-width-dropdown {
  .dropdown {
    width: 100%;
  }
}

.filter-dropdown-map {
  .dropdown {
    position: absolute;
    width: 213px;
    z-index: 1;
    top: 10px;
    left: 443px;
  }

  .dropdown-content li>ul {
    left: 213px !important;
  }
}

.no-travels {
  font-family: "Alliance-Regular";
  background-color: var(--ion-color-stone-gray);
  box-shadow: 0 1px 1px rgba(29, 34, 41, 0.10);
  border-radius: 2px;
  color: var(--ion-color-deep-black);
  text-align: center;
  padding: 15px;
}

.box-white,
.mobile-box-white {
  ion-input.box-border {
    input.native-input {
      // border: 1px solid #DEDEDE;
      border: 1px solid var(--ion-color-deep-gray-inv);
      border-radius: 8px;
      width: 100%;
      margin-bottom: 23px;
    }
  }
}

.modal-perfil,
.modal-perfil-xs {
  .box-blue {
    .divider-box {
      width: 100%;
      border: 0.5px solid var(--ion-color-deep-gray-inv);
      margin: 15px 0px 5px;
    }

    .table-perfil {
      ion-label.modal-label {
        font-family: "Alliance-regular";
        font-size: 0.813rem;
      }

      ion-label.modal-label-title {
        font-family: "Alliance-regular";
        font-size: 0.875rem;
      }
    }

    .modal-label-title {
      font-family: "Alliance-regular";
      font-size: 0.875rem;
    }

    .modal-label {
      font-family: "Alliance-regular";
      font-size: 0.813rem;
    }
  }
}

.label-header-list {
  font-size: 1rem;
  float: left;
  margin-bottom: 2px;
  color: var(--ion-color-tech-blue);
}

.mobile-item-margin {
  width: 100%;
  margin: 13px 8px 13px 0 !important;
}

.label-header-list {
  margin-bottom: 2px !important;
}

.label-item-movil-span {
  color: var(--ion-color-deep-black);
}

/***** Wrapper Div Element General Class *****/
/***** Wrapper General Class *****/
.wrapper {
  height: 100%;
  width: 100%;
  display: grid;
  //grid-template-columns: 2fr 2fr 1fr;
  // grid-column-gap: 1em;
  // grid-row-gap: 1em;
  grid-gap: 0.875rem;
  //grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 5% 5% 5% 5% !important;
  padding-left: 80px;
  //grid-auto-rows: minmax(1000px, auto);
  text-align: right;
}

.wrapper>div {
  color: #eee;
  padding: 0.875rem;
}

.wrapper>div :nth-child(odd) {}

/***** Wrapper General Class *****/

/***** Cell General Class *****/
.cell-1,
.cell-2,
.cell-3,
.cell-4,
.cell-5,
.cell-6 {
  font-family: "Alliance-Regular";
  font-size: 0.875rem;
}

/***** Cell General Class *****/
/***** Wrapper Div Element General Class *****/

/***** Dashboard BSC Page Elements Class *****/
@for $index from 0 through 400 {
  $size: $index * 2;

  @if(($size % 2)==0) {
    .table-column-#{$size} {
      min-width: #{$size}#{"px"};
    }
  }
}

.fill-red-light {
  fill: var(--ion-color-red-light) !important;
}

.fill-red-lighten {
  fill: var(--ion-color-red-lighten);
}

.bg-dark-blue {
  // background: var(--ion-color-dark-blue);
  //background: #222b35;
  background: #1e88e5;

}

.bg-dark-gray {
  // background: var(--ion-color-dark-blue);
  //background: #323f4f;
  background: rgba(83, 86, 87, 1);
}

.bg-light-blue {
  // background: var(--ion-color-dark-blue);
  background: #abb9ca;
}

.bg-light-gray {
  // background: var(--ion-color-dark-blue);
  // background: #d5dce3;
  background: rgba(83, 86, 87, 0.25);
}

.ion-no-padding-dashboard-bsc-header {
  padding: 0px;
}

.ion-padding-dashboard-card {
  padding: 16px 8px;
}

.bsc-main-header {
  font-family: 'Otypical-Regular';
  font-weight: 700;
  font-size: 16px;
  color: var(--ion-color-stone-gray);
}

ion-button.bar-btn-custom {
  font-family: "Otypical-Regular";
  font-size: 14px;
}

.custom-box-shadow {
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 25%);
}

.header-table-input {
  color: var(--ion-color-deep-black);
  font-family: 'Alliance-Regular';
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 18px;
}

.loading-chart {
  padding: 5px;
  background: white;
  width: 100%;
  min-height: 400px;
}

.custom-dimension {
  height: 410px;
  max-height: 450px;
  max-width: 100%;
  overflow: auto;
}

.btn-forma-text {
  font-family: "Otypical-Regular";
  font-size: 14px;
  letter-spacing: .29px;
}

.btn-forma {
  clip-path: polygon(0 0, 100% 0, 100% 60%, 85% 100%, 0 100%);
}

.btn-forma-consulta-cd {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
}


.modal-perfil-close-btn {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 80% 100%, 0 100%);
}

.prev-btn-forma {
  // clip-path: polygon(0 0, 100% 0, 100% 70%, 80% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
}

.next-btn-forma {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 80% 100%, 0 100%);
}

.modal-perfil-close-pos {
  left: -35px;
  top: 20px;
}

.modal-perfil-xs-close-pos {
  top: -20px;
}

ion-button.btn-forma::part(native),
ion-button.prev-btn-forma::part(native),
ion-button.next-btn-forma::part(native),
ion-button.modal-perfil-close-btn::part(native),
ion-button.no-rounded-corners::part(native),
ion-button.btn-forma-consulta-cd::part(native) {
  border-radius: 0px;
}


.btn-dashboard-active {
  background-color: var(--ion-color-jordy-blue);
  color: var(--ion-color-deep-black) !important;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 85% 100%, 0 100%);
}

ion-button.btn-dashboard-active::part(native) {
  color: var(--ion-color-deep-black) !important;
}

@media (max-width: 767px) {
  h2.bsc-main-header {
    margin-bottom: 16px !important;
  }

  .bsc-chart-header-select {
    height: auto;
  }
}

table.mat-table.mat-multiply-headers-sticky {
  thead {
    top: 0;
    position: sticky !important;
    z-index: 1000;
  }

  tbody:before {
    content: none !important;
  }
}

.table-main-header {
  // color: #000000;
  color: var(--ion-color-deep-black);
  text-transform: uppercase;
}

mat-header-row.mat-table-main-header {
  // background: rgba(65, 195, 238, 0.75);

  mat-header-cell {
    @extend .table-main-header;
    font-weight: 700;
  }
}

.bsc-chart-header-select {
  // background: rgba(65, 195, 238, 0.75);
  // background: var(--ion-color-gainsboro);
  background: var(--ion-color-stone-gray);
  height: 56px;
  display: grid;
  align-items: center;

  @extend .table-main-header;
  font-weight: 700;
}

.bsc-chart-table-headers {
  // background: rgba(65, 195, 238, 0.25);
  // background: var(--ion-color-gainsboro);
  background: var(--ion-color-stone-gray);
  height: 56px;
  display: grid;
  align-items: center;
  // color: #0000008a;
  color: var(--ion-color-deep-black);
  text-transform: uppercase;
  font-weight: 700;
}

.bsc-chart-table-rows {
  // background: var(--ion-color-gainsboro);
  background: var(--ion-color-stone-gray);
  height: 56px;
  display: grid;
  align-items: center;
}

.bsc-chart-table-border {
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

mat-form-field.mt-datepicker-container {
  font-family: "Alliance-Regular";
  font-size: 11px;
  font-weight: 700;

  .mat-form-field-wrapper {
    padding-bottom: 0px;

    .mat-form-field-flex {
      background: transparent;
      padding: 0px;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mt-datepicker-input-color:disabled {
  color: var(--ion-color-deep-black);
  opacity: 0.4;
  font-family: "Alliance-Regular";
}

mat-datepicker-toggle.mt-datepicker-toggle-pos {
  top: 5px;
  position: relative;
  color: var(--ion-color-deep-black);
}

.mat-ion-label-date-input {
  font-family: "Alliance-Regular";
  font-size: 11px;
  font-weight: 700;
  text-transform: capitalize;
}

.mat-calendar {
  font-family: "Alliance-Regular";
  font-size: 11px;
  font-weight: 700;
}

.mat-input-element {
  text-transform: capitalize;
}

.mat-calendar-body-selected {
  background-color: #1e88e5;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(30, 136, 229, 0.3);
}

/***** Div Content Container Class *****/
.modal-wrap {
  height: 100%;

  .modal-wrap-box {
    padding-top: 4%;
    padding-bottom: 8%;
    flex: none;
  }

  ion-row:first-child {
    height: 100%;
  }
}

@media (min-width: 320px) and (max-width: 812px) {
  .modal-wrap {
    width: 100%;

    .modal-wrap-box {
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
    }

    @media (max-height: 414px) {
      height: auto;

      ion-row:first-child {
        height: auto;
      }
    }
  }
}

/***** Div Content Container Class *****/

/***** FIN - Dashboard BSC Page Elements Class *****/

/***** ECharts Element General Class *****/
.bar-chart {
  position: relative;
  width: 100%;
  min-height: 400px;
  // overflow: hidden;
}

/***** ECharts Element General Class *****/

/***** SwiperJS Element General Class *****/
ion-content.transparent-modal::part(background) {
  background: rgba(44, 39, 45, 0.2) !important;

  &::part(content) {
    backdrop-filter: blur(12px);
  }
}

.transparent-modal {
  --background: rgba(44, 39, 45, 0.2);

  &::part(content) {
    backdrop-filter: blur(12px);
  }
}

.swiper {
  width: 100% !important;
  height: 100%;
  min-height: 350px;
  max-width: 1020px;
}

.swiper-pagination {
  bottom: -10px;
}

.swiper-wrapper {
  width: 100% !important;
  max-width: 1020px;
}

.swiper-slide {
  width: 100% !important;
  max-width: 1020px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .swiper {
    max-width: 350px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .swiper {
    max-width: 500px;
  }
}

@media (min-width: 769px) and (max-width: 812px) {
  .swiper {
    max-width: 648px;
  }
}

@media (min-width: 813px) and (max-width: 840px) {
  .swiper {
    max-width: 700px;
  }
}

@media (min-width: 841px) and (max-width: 1024px) {
  .swiper {
    max-width: 900px;
  }
}

/***** SwiperJS Element General Class *****/

/***** 2.0 General Class *****/
/***** Dashboard Element General Class *****/

input,
textarea {
  caret-color: var(--ion-color-tech-blue);
}

.dashboard-information {
  padding-left: 0;
  padding-right: 0;
  padding-top: 50px;
}

.dashboard {
  position: absolute;
  display: inline-block;
  height: calc(100vh - 70px);
  bottom: 0;
  // background-color: var(--ion-color-stone-gray);
  background-color: var(--ion-color-gainsboro);
  width: calc(100vw - 300px);
  left: 300px;

  &.not-static-menu {
    @media (min-width: 768px) {
      width: calc(100vw - 80px);
      left: 80px;
    }
  }

  &.dashboard-full-width {
    width: 100vw !important;
    left: 0 !important;
  }
}

.dashboard-content {
  // position: relative;
  overflow-x: scroll;
  height: inherit;
}

.dashboard-header {
  padding: 55px 40px 40px;
  // background-color: var(--ion-color-stone-gray);
  background-color: var(--ion-color-gainsboro);
  width: 100%;

  .welcome {
    padding: 5px 15px;

    h2 {
      font-family: 'Otypical-Regular';
      color: var(--ion-color-deep-black);
      font-size: 50px;
      margin-bottom: 100px;
    }
  }

  .box-container {
    padding: 8px 16px;
    --w: 15.58px; // Unidad que controla el largo del contenedor.
    --h: 12.98px; // Unidad que controla el alto del contenedor.
    --s: 17; // Unidad que controla la Scala del contenedor.
    --b: 1px; // Unidad que controla el grosor del borde.

    .bg-box-tech-blue {
      background-color: var(--ion-color-tech-blue);
    }

    .bg-box-jordy-blue {
      background-color: var(--ion-color-jordy-blue);
    }

    .bg-box-purple-glow {
      background-color: var(--ion-color-purple-glow);
    }

    .bg-box-burnt-orange {
      background-color: var(--ion-color-burnt-orange);
    }

    .box {
      display: inline-block;
      position: relative;
      width: calc(100% - 30px);
      height: calc(var(--h) * var(--s));
      box-sizing: border-box;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
    }

    .border-box {
      position: absolute;
      top: var(--b);
      /* equal to border thickness */
      left: var(--b);
      /* equal to border thickness */
      width: calc(100% - var(--b) * 2);
      /* container height - (border thickness * 2) */
      height: calc(var(--h) * var(--s) - var(--b) * 2);
      /* container height - (border thickness * 2) */
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
      // background-color: var(--ion-color-stone-gray);
      background-color: var(--ion-color-gainsboro);
    }

    .card-box-icon {
      position: absolute;
      top: 30px;
      left: 38px;
      width: calc(100% - 62px); // Se resta la suma del movimiento a la izq. mas el padding al contenedor del div.
      height: calc(var(--h) * var(--s));
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
      background-color: var(--ion-color-deep-black);

      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        width: 50%;
      }

      .center-alt {
        width: 58%;
      }
    }

    .card-box-text {
      position: absolute;
      margin-top: 40px;
      left: 38px;

      h3,
      p {
        color: var(--ion-color-deep-black) !important;
      }

      h3 {
        margin: 10px 0;
      }
    }
  }

  @media (min-width: 1px) and (max-width: 540px) {
    .box-container {
      --s: 14; // Unidad que controla la Scala del contenedor.
      margin-bottom: 35%;
    }
  }

  @media (min-width: 540px) and (max-width: 920px) {
    .box-container {
      --s: 24; // Unidad que controla la Scala del contenedor.
      margin-bottom: 20%;

    }
  }

  @media screen and (min-width: 2048px) and (max-width: 2999px) {
    .box-container {
      .card-box-icon {
        .center {
          width: 30%;
        }

        .center-alt {
          width: 35%;
        }
      }
    }
  }

  @media screen and (min-width: 3000px) and (max-width: 3840px) {
    .box-container {
      .card-box-icon {
        .center {
          width: 20%;
        }

        .center-alt {
          width: 23%;
        }
      }
    }
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.icon-help-container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.dashboard-information {
  // background-color: var(--ion-color-stone-gray);
  background-color: var(--ion-color-gainsboro);
  padding: 34px 40px 30px;

  .information-header {
    margin-bottom: 30px;

    h2 {
      font-family: "Otypical-Regular";
      font-size: 30px;
      color: var(--ion-color-stone-gray);
      display: inline-block;
    }

    button {
      display: inline-block;
      width: 160px;
      margin-top: 10px;
    }

    .breadcrumbs,
    .breadcrumbs a {
      font-family: 'Roboto-Regular';
      font-size: 14px;
      color: var(--ion-color-deep-black);
      letter-spacing: .4px;
      line-height: 20px;
      margin-top: 4px;
      font-weight: 500;
      word-break: break-all;

      span a {
        color: var(--ion-color-deep-black);
      }

    }

    input.input-breadcrums {
      font-family: 'Alliance-Regular';
      font-size: 12px;
      color: var(--ion-color-tech-blue);
      letter-spacing: .34px;
      line-height: 12px;
      margin-top: 4px;
    }
  }

  .table {
    thead {
      border-radius: 2px;
      margin-bottom: 10px;
      background-color: var(--ion-color-deep-black);
      box-shadow: 0 1px 1px rgba(29, 34, 41, 0.10);
      @extend %transition;

      tr {
        th {
          height: 50px;
          font-family: 'Alliance-Regular';
          text-transform: uppercase;
          font-size: 14px;
          color: var(--ion-color-stone-gray);
          letter-spacing: .29px;
          line-height: 20px;
          text-align: center;
          font-weight: 300;
          vertical-align: middle;
          border-color: transparent;
          padding: 0 7px;
          min-width: 70px;

          &:after {
            content: "";
          }

          &:focus {
            outline: -webkit-focus-ring-color auto 0;
          }

          img {
            width: 9px;
          }

          a {
            color: var(--ion-color-deep-black);
          }
        }
      }

      span.sort-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: sub;
      }

      .sorting:after,
      .sorting_asc:after,
      .sorting_asc_disabled:after,
      .sorting_desc:after,
      .sorting_desc_disabled:after {
        display: none;
      }

      .sorting span {
        background: url("./assets/imgs/icons/icon-sort.svg") no-repeat center right;
      }

      .sorting_asc span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
      }

      .sorting_desc span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
      }

      .sorting_asc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }

      .sorting_desc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }
    }

    tbody {
      box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.10);
      border-radius: 2px;
      // border: 1.5px solid var(--ion-color-stone-gray);

      tr {
        background-color: var(--ion-color-deep-black);
        text-transform: uppercase;
        text-align: center;
        @extend %transition;

        border-left: 1.5px solid var(--ion-color-deep-gray-inv);
        border-right: 1.5px solid var(--ion-color-deep-gray-inv);

        &:first-child {
          border-top: 1.5px solid var(--ion-color-deep-gray-inv);

          td {
            border-top: none;
          }
        }

        &:last-child {
          border-bottom: 1.5px solid var(--ion-color-deep-gray-inv);
        }

        td {
          height: 80px;
          vertical-align: middle;
          font-family: 'Alliance-Regular';
          font-size: 13px;
          color: var(--ion-color-stone-gray);
          letter-spacing: .27px;
          line-height: 20px;
          border-color: var(--ion-color-deep-gray-inv);
          position: relative;
          padding: 0 7px;

          .status {
            position: absolute;
            border-radius: 2px;
            left: 0;
            width: 3px;
            height: 22px;
            background-color: var(--ion-color-aqua);
          }

          &:after {
            position: absolute;
            content: " ";
            z-index: 0;
            top: 0;
            // border-bottom: 1.5px solid var(--ion-color-white);
            left: 0;
            right: 0;
            @extend %transition;
          }

          a {
            color: var(--ion-color-deep-black);
          }
        }

        &:hover {
          background-color: var(--ion-color-jordy-blue) !important;
          color: var(--ion-color-deep-black) !important;

          td {
            color: var(--ion-color-deep-black);
            @extend %transition;

            a {
              color: var(--ion-color-deep-black);
            }

            &:after {
              border-color: var(--ion-color-jordy-blue);
            }

            svg {
              g {
                fill: var(--ion-color-deep-black);
              }

              path {
                fill: var(--ion-color-deep-black);
              }
            }
          }
        }
      }
    }
  }

  table.table.gray-bg-table {
    thead {
      border-radius: 2px;
      margin-bottom: 10px;
      // background-color: var(--ion-color-gainsboro);
      background-color: var(--ion-color-stone-gray);
      box-shadow: 0 1px 1px rgba(29, 34, 41, 0.10);
      @extend %transition;

      tr {
        th {
          height: 50px;
          font-family: 'Alliance-Regular';
          text-transform: uppercase;
          font-size: 14px;
          color: var(--ion-color-deep-black);
          letter-spacing: .29px;
          line-height: 20px;
          text-align: center;
          font-weight: 300;
          vertical-align: middle;
          border-color: transparent;
          padding: 0 7px;
          min-width: 70px;

          &:after {
            content: "";
          }

          &:focus {
            outline: -webkit-focus-ring-color auto 0;
          }

          img {
            width: 9px;
          }

          a {
            color: var(--ion-color-deep-black);
          }

          label {
            font-family: 'Alliance-Regular';
            font-size: 14px;
            color: var(--ion-color-deep-black);
          }
        }
      }

      span.sort-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: sub;
      }

      .sorting:after,
      .sorting_asc:after,
      .sorting_asc_disabled:after,
      .sorting_desc:after,
      .sorting_desc_disabled:after {
        display: none;
      }

      .sorting span {
        background: url("./assets/imgs/icons/icon-sort.svg") no-repeat center right;
      }

      .sorting_asc span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
      }

      .sorting_desc span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
      }

      .sorting_asc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-asc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }

      .sorting_desc_disabled span {
        background: url("./assets/imgs/icons/icon-sort-desc.svg") no-repeat center right;
        opacity: .5;
        cursor: not-allowed;
      }
    }

    tbody {
      box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.10);
      border-radius: 2px;
      // border: 1.5px solid var(--ion-color-stone-gray);

      tr {
        // background-color: var(--ion-color-gainsboro);
        background-color: var(--ion-color-stone-gray);
        text-transform: uppercase;
        text-align: center;
        @extend %transition;

        // border-left: 1.5px solid var(--ion-color-deep-black);
        // border-right: 1.5px solid var(--ion-color-deep-black);

        &:first-child {
          // border-top: 1.5px solid var(--ion-color-deep-black);

          td {
            border-top: none;
          }
        }

        &:last-child {
          // border-bottom: 1.5px solid var(--ion-color-deep-black);
        }

        td {
          height: 80px;
          vertical-align: middle;
          font-family: 'Alliance-Regular';
          font-size: 13px;
          color: var(--ion-color-deep-black);
          letter-spacing: .27px;
          line-height: 20px;
          border-color: var(--ion-color-deep-gray-inv);
          position: relative;
          padding: 0 7px;

          .status {
            position: absolute;
            border-radius: 2px;
            left: 0;
            width: 3px;
            height: 22px;
            background-color: var(--ion-color-tech-blue);
          }

          &:after {
            position: absolute;
            content: " ";
            z-index: 0;
            top: 0;
            // border-bottom: 1.5px solid var(--ion-color-white);
            left: 0;
            right: 0;
            @extend %transition;
          }

          a {
            color: var(--ion-color-deep-black);
          }
        }

        &:hover {
          background-color: var(--ion-color-jordy-blue) !important;
          color: var(--ion-color-deep-black) !important;

          td {
            color: var(--ion-color-deep-black) !important;
            @extend %transition;

            a {
              color: var(--ion-color-deep-black) !important;
            }

            &:after {
              border-color: var(--ion-color-jordy-blue);
            }

            svg {
              g {
                fill: var(--ion-color-deep-black);
              }

              path {
                fill: var(--ion-color-deep-black);
              }
            }
          }
        }
      }
    }
  }

  .table.without-line-th {
    tbody {
      tr {
        td {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

.dashboard-information .categories button {
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {

  .dashboard,
  .dashboard {
    width: 100%;
    left: 0;
    top: 100px;
    height: calc(100vh - 70px);

    .dashboard-information,
    .dashboard-information {
      padding-top: 55px;
      padding-bottom: 55px;
    }

    .dashboard-header,
    .dashboard-header {
      padding-left: 32px;
      padding-right: 32px;

      .squared-link {
        .squared-option {
          margin-bottom: 30px;
        }
      }
    }
  }

  table.table {
    thead {
      tr {
        th:nth-child(8) {
          min-width: 100px;
        }

        th:nth-child(6) {
          min-width: 80px;
        }
      }
    }

    tbody {
      td {
        letter-spacing: .27px !important;
        line-height: 20px !important;

        span {
          display: block;
          text-align: left;
        }
      }
    }
  }

  .categories button {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  /* .add-padding-mobile {
    padding-top: 30px;
  } */

  .dashboard,
  .dashboard {
    width: 100%;
    left: 0;
    height: calc(100vh - 100px);
    // position: inherit;
    // margin-top: 62px;
    // height: calc(100vh - 62px);

    h2 {
      margin-bottom: 46px;
    }

    .dashboard-header,
    .dashboard-header {
      padding-left: 0;
      padding-right: 0;

      .squared-link {
        .squared-option {
          margin-bottom: 30px;
          padding: 20px 15px;

          .icon-circle {
            width: 50px;
            height: 50px;
            margin-bottom: 15px;
          }

          h3 {
            margin-bottom: 0;
          }

          p {
            display: none;
          }
        }
      }
    }

    .dashboard-information,
    .dashboard-information {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;

      .information-header {
        margin-bottom: 35px;
        margin-top: 10%;

        a {
          float: left;
          margin-top: 35px;
        }

        h2 {
          margin-bottom: 0;
        }
      }

      .table {
        thead {
          tr {
            th {
              padding: 0 10px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 0 10px;
            }
          }
        }
      }
    }

    .movilCD {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .information-header {
    .filter {
      margin-top: 6px !important;
      float: right !important;
      width: auto !important;
    }
  }

  .mobile-list-item {
    position: relative;
    padding: 29px 26px 25px 22px;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    background-color: var(--ion-color-stone-gray);
    border-top: 1px solid var(--ion-color-stone-gray);

    h4 {
      font-family: 'Roboto-Regular';
      font-size: 14px;
      color: var(--ion-color-deep-black);
      letter-spacing: .29px;
    }

    .divider {
      width: 100%;
      height: 1px;
      margin-top: 13px;
      background-color: var(--ion-color-tech-blue);
      margin-bottom: 13px;
    }

    .status {
      position: absolute;
      border-radius: 2px;
      left: 0;
      width: 3px;
      height: 22px;
      background-color: var(--ion-color-tech-blue);
    }

    .bookmark {
      position: absolute;
      top: 0;
      right: 7px;

      img {
        vertical-align: top;
      }
    }

    .stars-wrap {
      float: right;

      svg {
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--ion-color-deep-black);
    }

    span {
      color: var(--ion-color-tech-blue);
      margin-right: 10px;
      font-family: 'Alliance-Regular';
      font-size: 13px;
      letter-spacing: .27px;
    }
  }

  .categories {
    overflow-y: scroll;
    width: 100%;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .box-white {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .item {
    // max-width: 260px;
    margin: 0 auto;
  }
}

@media (min-width: 920px) and (max-width: 1120px) {

  .dashboard,
  .dashboard {

    .dashboard-content {
      .dashboard-information {
        padding-top: 162px;
      }
    }
  }
}

.img-rotate-90deg {
  transform: rotate(90deg);
}

.img-rotate-180deg {
  transform: rotate(180deg);
}

.img-rotate-270deg {
  transform: rotate(270deg);
}

.img-rotate-360deg {
  transform: rotate(360deg);
}

.img-rotate-90deg-flip {
  transform: rotate(90deg) scaleX(-1);
}

.img-rotate-180deg-flip {
  transform: rotate(180deg) scaleX(-1);
}

.img-rotate-270deg-flip {
  transform: rotate(270deg) scaleX(-1);
}

.img-rotate-360deg-flip {
  transform: rotate(360deg) scaleX(-1);
}

.img-flip {
  transform: scaleX(-1);
}

/***** 2.0 General Class *****/

/***** Header Element General Class *****/
header.navbar-header {
  .menu img {
    width: 27px;
  }

  width: 100%;
  height: 70px;
  z-index: 2;
  position: fixed;
  background-color: var(--ion-color-gainsboro);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);

  h4 {
    color: var(--ion-color-deep-black);
    line-height: 0;
  }

  .navbar-header-logo {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;

    .logo-img-desktop {
      margin-left: calc(355px - 16px);

      &.not-static-menu {
        margin-left: 114px;
      }
    }

    .title-page-desktop {
      margin-left: calc(355px - 16px);

      &.not-static-menu {
        margin-left: 114px;
      }
    }
  }

  span.ion-title-color-custom {
    font-family: "Otypical-Regular";
    font-size: 32px;
    color: var(--ion-color-tech-blue);
  }

  .navbar-col-full-center {
    // margin-top: 1%;
    display: flex;
    align-items: center;
  }

  .navbar-header-col-full-center {
    height: 70px;
    display: flex;
    align-items: center;
  }

  .grid {
    padding: 0;

    .input-underline {
      margin-top: 20px;
    }

    .row {
      .col {
        padding: 0 15px 0 15px;

        button {
          ion-icon {
            width: 22px !important;
            height: 21px !important;
            color: var(--ion-color-white);
          }
        }
      }
    }
  }

  .item-md {
    padding: 0;
    background-color: transparent;

    .item-inner {
      padding: 0 !important;
    }
  }

  .input-md {
    width: 100%;
  }

  .text-input-md {
    margin: 0;
    width: 100%;
  }

  .mobile-header-text {
    display: inline-block;
    margin-left: 16px;
    font-size: 1.9em;
    vertical-align: middle;
  }

  .header-logo-text {
    margin-left: 85px;
    margin-top: 25px;
    font-size: 1.5em;
  }

  .navbar-header-right {

    ion-button {
      --ripple-color: var(--ion-color-jordy-blue);
    }

    .helper-btn {
      padding: 0;
      margin: 0;
    }

    .filters {
      padding: 0;
      margin: 0;
    }

    .notification {
      padding: 0;
      margin: 0;
    }

    .notification,
    .filters {
      /* position: relative;
          margin-right: 28px;
          padding: 1px 7px 2px 0; */

      img {
        width: 19px;
      }

      .notification-number {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 9px;
        padding: 3px 8px 3px 4px;
      }
    }

    .notification-content {
      width: 360px;
      position: fixed;
      top: 65px;
      right: 40px;
      background-color: var(--ion-color-stone-gray);
      box-shadow: -1px 0px 5px 5px rgba(29, 34, 41, 0.1);
      height: auto;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &.notification-visible {
        visibility: visible;
        opacity: 1;
      }

      .notification-title {
        background-color: var(--ion-color-gainsboro);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 18px;
        padding-right: 18px;

        p {
          font-family: "Roboto-Regular";
          color: var(--ion-color-deep-black);
        }
      }

      .notification-column {
        max-height: 400px;
        height: auto;
        overflow-y: auto;
      }

      .notification-item {
        border-bottom: solid 1px var(--ion-color-gainsboro);

        a {
          display: block;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 18px;
          padding-right: 18px;
        }

        p {
          font-family: "Alliance-Regular";
          font-size: 13px;
          color: var(--ion-color-deep-black);
          letter-spacing: 0.27px;
          line-height: 20px;
        }
      }
    }

    h4 {
      display: inline-block;
    }

    .avatar {
      display: inline-block;
      width: 45px;
      border-radius: 50%;
    }
  }
}

/***** button/ion-button Element General Class *****/
.box-btn-forma {
  width: 160px;
  height: 40px;

  .btn-forma {
    //Forma design
    width: calc(100%);
    height: calc(100%);
    box-sizing: border-box;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 85% 100%, 0 100%);

    //Center content in the middle 
    display: flex;
    justify-content: center;
    align-items: center;
    //Texto format
    font-family: "Otypical-Regular";
    font-size: 16px;
    color: var(--ion-color-stone-gray);
    letter-spacing: 0.29px;
  }

  ion-button {
    --background: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --box-shadow: none;
    --ripple-color: var(--ion-color-deep-black);
    text-transform: none;
  }
}

.btn-rounded {
  font-family: "Otypical-Regular";
  color: var(--ion-color-deep-black);
}

.btn-forma-activate {
  @extend .btn-forma;

  font-family: "Otypical-Regular";
  font-size: 14px;
  padding: 7px 17px;
  color: var(--ion-color-deep-black);

  letter-spacing: .29px;
  @extend %transition;

  &:hover {
    // background-color: darken($aqua, 10%);
    background-color: var(--ion-color-synthetic-blue);
    color: var(--ion-color-tech-blue);
  }
}

.box-full-btn-forma {
  width: 100%;
  height: 40px;

  .btn-forma {
    //Forma design
    width: calc(100%);
    height: calc(100%);
    box-sizing: border-box;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 85% 100%, 0 100%);

    //Center content in the middle 
    display: flex;
    justify-content: center;
    align-items: center;
    //Texto format
    // font-family: "Otypical-Regular";
    // font-size: 16px;
    color: var(--ion-color-stone-gray);
    letter-spacing: 0.29px;
  }

  ion-button {
    --background: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --box-shadow: none;
    --ripple-color: var(--ion-color-deep-black);
    text-transform: none;
  }
}

/***** button/ion-button Element General Class *****/

.center-box {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.vertical-center-box {
  display: flex !important;
  justify-content: start;
  align-items: center;
}

.scroll-content-x {
  overflow-x: scroll;
}

.scroll-content-y {
  overflow-y: scroll;
}

/*** Ion Selectable Modal  Class ***/
/***** ion-item Element General Class (Dentro de los Ion Selectable) *****/
ionic-selectable-modal {
  ion-content::part(background) {
    background: var(--ion-color-stone-gray);
  }

  ion-list {
    ion-item-group {
      ion-item.ionic-selectable-item {
        ion-label {
          font-family: "Alliance-Regular" !important;
          font-size: 1rem !important;
        }
      }
    }
  }

  ion-footer {
    ion-toolbar {
      ion-button {
        font-family: "Alliance-Regular";
        font-size: 0.875rem !important;
        color: var(--ion-color-deep-black);
        clip-path: polygon(0 0, 100% 0, 100% 65%, 97% 100%, 0 100%);


        &::part(native) {
          background-color: var(--ion-color-tech-blue);

        }
      }
    }
  }
}

/***** ion-item Element General Class (Dentro de los Ion Selectable) *****/


ionic-selectable-modal {
  font-family: "Alliance-Regular";

  ion-toolbar,
  ion-buttons,
  ion-buttons ion-button {
    font-family: "Alliance-Regular";

  }

  ion-toolbar ion-searchbar,
  .searchbar-input input {
    font-family: "Alliance-Regular";
  }

  ion-content {
    font-family: "Alliance-Regular";
    background-color: var(--ion-color-stone-gray);
    color: var(--ion-color-deep-black);

    ion-list.list-md {
      font-family: "Alliance-Regular";
      background-color: var(--ion-color-stone-gray);
      color: var(--ion-color-deep-black);
      padding-top: 0px;

      ion-item-group ion-item {
        font-family: "Alliance-Regular";
        background-color: var(--ion-color-stone-gray);
        color: var(--ion-color-deep-black);

        ion-icon {
          color: var(--ion-color-tech-blue);
          fill: var(--ion-color-tech-blue);
        }
      }

      ion-item-group ion-item::part(native) {
        background-color: var(--ion-color-stone-gray);
        color: var(--ion-color-deep-black);
      }
    }
  }

}

/*** Ion Selectable Modal  Class ***/


/*** Utils Input Class ***/
.input-underline input {
  padding-bottom: 7px !important;
  color: var(--ion-color-deep-black) !important;
  border-bottom: solid 1px var(--ion-color-gainsboro) !important;

  &:focus {
    color: var(--ion-color-tech-blue) !important;
    border-bottom: solid 1px var(--ion-color-tech-blue) !important;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--ion-color-tech-blue) !important;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--ion-color-tech-blue) !important;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--ion-color-tech-blue) !important;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--ion-color-tech-blue) !important;
    }
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--ion-color-deep-black) !important;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--ion-color-deep-black) !important;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--ion-color-deep-black) !important;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--ion-color-deep-black) !important;
  }
}

/*** Utils Input Class ***/
:host .picker-item {
  font-family: 'Alliance-Regular' !important;
  text-transform: capitalize !important;
}

/************************************************** Reestructuración de Class **************************************************/

/************************************************** Light Mode Class **************************************************/
/**+ Clases adicionales para el modo light ***/

.h-color-custom,
.p-color-custom {
  color: var(--ion-color-deep-black);
  --color: var(--ion-color-deep-black);
}

ion-content.content-color-custom::part(background) {
  background: var(--ion-color-gainsboro);
}

ion-content.content-color-custom::part(scroll) {
  color: var(--ion-color-deep-black);
}

ion-content.content-color-custom {
  --background: var(--ion-color-gainsboro);
  --color: var(--ion-color-deep-black);
}

.container-box-bg-color-custom {
  background-color: var(--ion-color-stone-gray);
  color: var(--ion-color-deep-black);

  h3,
  p,
  a {
    color: var(--ion-color-deep-black);
  }
}

ion-toolbar.toolbar-color-custom {
  --background: var(--ion-color-stone-gray);
  --color: var(--ion-color-deep-black);

  ion-title {
    color: var(--ion-color-deep-black);
    --color: var(--ion-color-deep-black);
  }
}

img.navbar-logo-content {
  width: 20%;
  content: url('assets/imgs/logo/wordmark/Quantum-Wordmark-Blue.png');
}

@media screen and (min-width: 2048px) and (max-width: 2999px) {
  img.navbar-logo-content {
    width: 15%;
  }
}

@media screen and (min-width: 3000px) and (max-width: 3840px) {
  img.navbar-logo-content {
    width: 10%;
  }
}

img.btn-mobile-icon-menu {
  content: url('assets/imgs/icons/menu.png');
}

img.avatar-default-content {
  max-width: 80%;
  content: url('assets/imgs/logo/VPX-lockup/Quantum-VPX-Lockup.png');
}

ion-item.user-settings-header-list-item {
  margin: 0px !important;
  --background: var(--ion-color-gainsboro);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);

  ion-label {
    font-size: 14px !important;
  }
}

ion-item.user-settings-list-item {
  margin: 0px !important;
  --background: var(--ion-color-stone-gray);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);

  ion-label {
    font-size: 14px !important;
  }
}

ion-list.list-color-custom {
  background-color: var(--ion-color-stone-gray);
}

ion-list-header.list-header-color-custom {
  ion-label {
    color: var(--ion-color-light-black);
  }
}

ion-list-header.lb-list-header {
  background-color: var(--ion-color-stone-gray);
  color: var(--ion-color-deep-black);
}

ion-item-divider.item-divider-color-custom {
  --background: var(--ion-color-stone-gray);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);
}

ion-item.user-settings-header-list-item::part(native),
ion-item.user-settings-list-item::part(native) {
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-item.item-color-custom-no-hover { 
  --background-hover: transparent;
  --ripple-color: transparent !important;
}
ion-item.item-color-custom {
  --background: var(--ion-color-stone-gray);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);
}

ion-item.item-color-custom-inv {
  --background: var(--ion-color-gainsboro);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);
}

ion-label.label-color-custom,
label.label-color-custom {
  color: var(--ion-color-deep-black);
  --color: var(--ion-color-deep-black);
}

ionic-selectable.item-selectable-color-custom {
  color: var(--ion-color-deep-black);
}

ion-icon.icon-color-custom {
  color: var(--ion-color-deep-black);
  --color: var(--ion-color-deep-black);
}

ion-textarea.textarea-color-custom {
  border: 1px solid var(--ion-color-deep-gray-inv);
}

ion-searchbar.searchbar-color-custom {
  --background: var(--ion-color-stone-gray);
  --border-color: var(--ion-color-deep-gray-inv);
  --color: var(--ion-color-deep-black);

  --placeholder-color: var(--ion-color-deep-black);
  --icon-color: var(--ion-color-deep-black);
  --clear-button-color: var(--ion-color-deep-black);
  --cancel-button-color: var(--ion-color-deep-black);
}

ion-checkbox.checkbox-color-custom {
  --checkmark-color: var(--ion-color-deep-black);
  --background-checked: var(--ion-color-stone-gray);
}

ion-checkbox.checkbox-color-custom::part(container) {
  border: 2px solid var(--ion-color-deep-gray-inv);
}

ion-popover.navbar-filters-popover {
  .popover-viewport {
    font-family: "Alliance-Regular";
    background-color: var(--ion-color-stone-gray);

    .filter-label {
      font-family: "Alliance-Regular";
      color: var(--ion-color-deep-black);
    }
  }
}

/* E Charts class */

div.graph-pie-over-box {
  // border-bottom: 1px solid var(--ion-color-deep-gray-inv);
  // background-color: var(--ion-color-stone-gray);
  font-family: "Alliance-Regular";
  color: var(--ion-color-deep-black);
  font-size: 18px;
  padding-bottom: 7px;
  margin-bottom: 7px
}

/**+ FIN - Clases adicionales para el modo light ***/
/************************************************** FIN - Light Mode Class **************************************************/

/************************************************** Dark Mode Class **************************************************/

body.dark {
  ion-content.login-content::part(background) {
    background: var(--ion-color-light-black);
  }

  .login-wrap {

    .btn {
      color: var(--ion-color-stone-gray) !important;
      --ripple-color: rgba(158, 158, 158, .1) !important;
    }

    .login {
      background-color: var(--ion-color-deep-gray) !important;

      .logo-wrap-content {
        content: url('assets/imgs/logo/VPX-lockup/Quantum-VPX-Lockup-blue.png');
      }

      .label-wrap {}

      form {
        ion-input {
          --placeholder-color: var(--ion-color-stone-gray);
        }

        a {
          color: var(--ion-color-stone-gray);
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 812px) {
    .login-wrap {

      .login {
        background-color: transparent;
      }
    }
  }

  header.navbar-header {
    background-color: var(--ion-color-deep-gray);

    h4 {
      color: var(--ion-color-stone-gray);
    }

    span.ion-title-color-custom {
      color: var(--ion-color-stone-gray);
    }

    .navbar-header-right {

      .notification-content {
        background-color: var(--ion-color-light-black);
        box-shadow: -1px 5px 5px rgba(226, 221, 214, 0.10);

        .notification-title {
          background-color: var(--ion-color-deep-gray);

          p {
            color: var(--ion-color-stone-gray);
          }
        }

        .notification-item {
          border-bottom: solid 1px var(--ion-color-stone-gray);

          p {
            color: var(--ion-color-stone-gray);
          }
        }
      }
    }
  }

  .dashboard {
    background-color: var(--ion-color-light-black);
  }


  .dashboard-header {
    background-color: var(--ion-color-light-black);

    .welcome {
      h2 {
        color: var(--ion-color-stone-gray);
      }
    }

    .box-container {
      .border-box {
        background-color: var(--ion-color-light-black);
      }

      .card-box-icon {
        background-color: var(--ion-color-deep-gray);
      }

      .card-box-text {

        h3,
        p {
          color: var(--ion-color-stone-gray) !important;
        }
      }
    }
  }

  .dashboard-information {
    background-color: var(--ion-color-light-black);

    .information-header {

      h2 {
        color: var(--ion-color-stone-gray);
      }

      .breadcrumbs,
      .breadcrumbs a {
        color: var(--ion-color-gainsboro);

        span a {
          color: var(--ion-color-stone-gray);
        }

      }

      input.input-breadcrums {
        color: var(--ion-color-tech-blue);
      }
    }

    .table {
      tbody {
        tr {
          border-left: 1.5px solid var(--ion-color-stone-gray-inv);
          border-right: 1.5px solid var(--ion-color-stone-gray-inv);

          &:first-child {
            border-top: 1.5px solid var(--ion-color-stone-gray-inv);
          }

          &:last-child {
            border-bottom: 1.5px solid var(--ion-color-stone-gray-inv);
          }

          td {
            border-color: var(--ion-color-stone-gray-inv);
          }
        }
      }
    }

    table.table.gray-bg-table {

      thead {
        background-color: var(--ion-color-deep-gray);

        tr {
          th {
            color: var(--ion-color-stone-gray);

            a {
              color: var(--ion-color-stone-gray);
            }

            label {
              color: var(--ion-color-stone-gray);
            }
          }
        }
      }

      tbody {
        tr {
          background-color: var(--ion-color-deep-gray);

          td {
            color: var(--ion-color-stone-gray);
            border-color: var(--ion-color-stone-gray-inv);

            a {
              color: var(--ion-color-stone-gray);
            }
          }
        }

      }
    }
  }

  .box-white {
    background: var(--ion-color-deep-gray);
    box-shadow: none;

    h4 {
      color: var(--ion-color-tech-blue);
    }

    h3,
    p,
    span {
      color: var(--ion-color-stone-gray);
    }
  }

  .location-track {
    color: var(--ion-color-stone-gray);

    ul {
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      margin: 10px 0;

      li {
        border-left: 1px solid var(--ion-color-stone-gray);

        &.active:first-child::before {
          background: var(--ion-color-deep-gray);
          border: 1px solid var(--ion-color-tech-blue);
        }

        &:before {
          background: var(--ion-color-deep-gray);
          border: 1px solid var(--ion-color-stone-gray);
        }

        &.active {

          & span {
            color: var(--ion-color-stone-gray);
          }
        }
      }
    }
  }

  .table-main-header {
    color: var(--ion-color-stone-gray);
  }

  .bsc-chart-header-select {
    background: var(--ion-color-deep-gray);
  }

  .bsc-chart-table-headers {
    background: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  .bsc-chart-table-rows {
    background: var(--ion-color-deep-gray);
  }

  .mt-datepicker-input-color:disabled {
    color: var(--ion-color-stone-gray);
  }

  mat-datepicker-toggle.mt-datepicker-toggle-pos {
    color: var(--ion-color-stone-gray);
  }

  .mat-calendar {
    background: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: rgba(239, 240, 240, 0.54);
  }

  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator {
    color: rgba(239, 240, 240, 0.87);
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: rgba(239, 240, 240, 0.38);
  }

  .mat-calendar-body-selected {
    color: rgba(0, 0, 0, 0.87);
  }

  ion-select.dashboard-bsc-selec,
  ion-select.map-tracking-filter {
    color: var(--ion-color-stone-gray);
  }

  ion-select.dashboard-bsc-select::part(text),
  ion-select.dashboard-bsc-select::part(icon),
  ion-select.map-tracking-filter::part(text),
  ion-select.map-tracking-filter::part(icon) {
    color: var(--ion-color-stone-gray);
  }

  .btn-rounded {
    color: var(--ion-color-stone-gray);
  }

  .dropdown {
    background-color: var(--ion-color-deep-gray);

    a,
    p {
      color: var(--ion-color-stone-gray);
    }
  }

  .dropdown-content {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);

    a {
      color: var(--ion-color-stone-gray);

      &:hover:not([disabled]) {
        background-color: var(--ion-color-tech-blue);
        color: var(--ion-color-deep-black);
      }
    }

    li {
      background-color: var(--ion-color-deep-gray);
      color: var(--ion-color-stone-gray);

      &:hover:not([disabled]) {
        background-color: var(--ion-color-tech-blue);
        color: var(--ion-color-deep-black);
      }
    }
  }

  /***** dx-table Element General Class *****/
  .dx-pivotgrid .dx-area-description-cell,
  .dx-pivotgrid .dx-column-header,
  .dx-pivotgrid .dx-data-header,
  .dx-pivotgrid .dx-total {
    background-color: var(--ion-color-deep-gray);
  }

  .dx-pivotgrid .dx-area-description-cell.dx-pivotgrid-background,
  .dx-pivotgrid .dx-column-header,
  .dx-pivotgrid .dx-data-header {
    background-color: var(--ion-color-deep-gray);
  }

  .dx-pivotgrid .dx-grandtotal {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  .dx-pivotgrid .dx-pivotgrid-area td {
    color: var(--ion-color-stone-gray);
  }

  dx-data-grid {

    .dx-datagrid-header-panel {
      border-left: 1px solid rgb(77, 77, 77);
      border-top: 1px solid rgb(77, 77, 77);
      border-right: 1px solid rgb(77, 77, 77);
      background-color: var(--ion-color-deep-gray) !important;

      .dx-toolbar {
        margin-top: 5px;
        padding-right: 5px;
        margin-bottom: 9px;
        background-color: var(--ion-color-deep-gray) !important;
      }
    }
  }

  .dx-pager .dx-page-sizes .dx-selection,
  .dx-pager .dx-pages .dx-selection {
    background-color: var(--ion-color-deep-gray);
  }

  ion-row.travel-list {

    .dx-datagrid-table {
      background-color: var(--ion-color-deep-gray) !important;
      -webkit-box-shadow: 0 1px 1px rgba(226, 221, 214, 0.1) !important;
      box-shadow: 0 1px 1px rgba(226, 221, 214, 0.1) !important;
    }

    .dx-header-row td {
      color: var(--ion-color-stone-gray) !important;

      &:hover {
        background-color: var(--ion-color-stone-gray-inv);
        color: var(--ion-color-deep-gray-inv) !important;
      }
    }

    .dx-header-row {
      background-color: var(--ion-color-deep-gray) !important;
      -webkit-box-shadow: 0 1px 1px rgba(226, 221, 214, 0.1) !important;
      box-shadow: 0 1px 1px rgba(226, 221, 214, 0.1) !important;
    }

    .dx-datagrid-table tbody tr {
      background-color: var(--ion-color-deep-gray);

      td {
        color: var(--ion-color-stone-gray);
        border-color: var(--ion-color-stone-gray);
      }
    }
  }

  ion-row.matriz-list {

    .dx-header-row {
      background-color: var(--ion-color-deep-gray) !important;
      color: var(--ion-color-stone-gray) !important;

      td {
        border-color: var(--ion-color-stone-gray) !important;
      }
    }
  }

  .dx-widget {
    color: var(--ion-color-stone-gray);
  }

  /***** dx-table Element General Class *****/

  .no-travels {
    background-color: var(--ion-color-deep-gray);
    box-shadow: 0 1px 1px rgba(226, 221, 214, 0.10);
    color: var(--ion-color-stone-gray);
  }

  .label-item-movil-span {
    color: var(--ion-color-stone-gray);
  }

  /***** ion-alert Element General Class *****/
  ion-alert.base-alert .alert-wrapper,
  ion-alert.select-alert .alert-wrapper,
  ion-alert.dashboard-bsc-select-alert .alert-wrapper {
    background: var(--ion-color-deep-gray);

    .alert-head {
      .alert-title {
        color: var(--ion-color-stone-gray);
      }
    }

    .alert-message {
      color: rgba(239, 240, 240, 0.5);
    }

    .alert-button-group {
      .alert-button {
        color: var(--ion-color-stone-gray);
      }
    }

    .alert-checkbox-group {
      .alert-checkbox {
        .alert-button-inner {
          .alert-checkbox-label {
            color: var(--ion-color-stone-gray);
          }

        }
      }
    }

    .alert-radio-group {
      border-color: var(--ion-color-stone-gray-inv);

      .alert-radio {
        .alert-button-inner {

          .alert-radio-label {
            color: var(--ion-color-stone-gray);
          }
        }
      }
    }
  }

  /***** ion-alert Element General Class *****/

  .font-color-grid {
    color: var(--ion-color-stone-gray);
  }

  .change-page-div {
    color: var(--ion-color-stone-gray);

    .page {
      color: var(--ion-color-tech-blue);
      background-color: var(--ion-color-deep-gray);
    }

    .change-page-arrow {
      color: var(--ion-color-stone-gray);
      background-color: var(--ion-color-deep-gray);
    }
  }

  .box-white,
  .mobile-box-white {
    ion-input.box-border {
      input.native-input {
        border: 1px solid var(--ion-color-stone-gray-inv);
      }
    }
  }

  /******************************* dx-table class *******************************/
  @import "./sass/pivotGrid.dark";

  /******************************* dx-table class *******************************/

  /***** dx-table Element General Class *****/
  dx-date-box {
    input.dx-texteditor-input {
      color: var(--ion-color-stone-gray);
    }
  }

  dx-date-box.dx-texteditor.dx-editor-outlined {
    border-radius: 0px;
    background: var(--ion-color-deep-gray);
    border-color: var(--ion-color-deep-gray);
  }

  .ion-label-date-input {
    color: var(--ion-color-stone-gray);
  }

  .dx-popup-wrapper>.dx-overlay-content {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);

    .dx-calendar {
      background-color: var(--ion-color-deep-gray);
    }

    .dx-calendar-navigator .dx-button {
      border-color: var(--ion-color-deep-gray);
    }

    .dx-button-mode-contained {
      background-color: var(--ion-color-deep-gray);
    }

    .dx-calendar-cell {
      color: var(--ion-color-stone-gray);
    }

    .dx-calendar-cell.dx-calendar-empty-cell:not(.dx-calendar-selected-date),
    .dx-calendar-cell.dx-calendar-empty-cell:not(.dx-calendar-selected-date).dx-state-active,
    .dx-calendar-cell.dx-calendar-empty-cell:not(.dx-calendar-selected-date).dx-state-hover,
    .dx-calendar-cell.dx-calendar-other-view:not(.dx-calendar-selected-date),
    .dx-calendar-cell.dx-calendar-other-view:not(.dx-calendar-selected-date).dx-state-active,
    .dx-calendar-cell.dx-calendar-other-view:not(.dx-calendar-selected-date).dx-state-hover {
      color: var(--ion-color-light-black);
    }

    .dx-calendar-cell.dx-state-hover {
      -webkit-box-shadow: inset 0 -1px 0 1000px rgba(255, 255, 255, .2);
      box-shadow: inset 0 -1px 0 1000px rgba(255, 255, 255, .2);
    }
  }

  /***** dx-table Element General Class *****/


  /***** ion-modal Element General Class *****/

  ion-content.selectable-modal::part(background) {
    background-color: var(--ion-color-light-black) !important;
  }

  ion-content.m-modal::part(background) {
    background: none !important;
    background-color: var(--ion-color-light-black) !important;
  }

  .m-modal {
    background: none !important;
    background-color: var(--ion-color-light-black) !important;

    .m-modal-body {
      .m-modal-selected-fields {
        padding: 0;

        .m-modal-row-list,
        .m-modal-col-list {
          height: 100%;
          background-color: var(--ion-color-deep-gray);
        }

        .m-modal-row-list {
          ion-list-header {
            ion-label {
              color: var(--ion-color-stone-gray);
            }
          }
        }
      }
    }
  }

  .input-underline input {
    color: var(--ion-color-stone-gray) !important;
    border-bottom: solid 1px var(--ion-color-stone-gray) !important;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--ion-color-stone-gray) !important;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--ion-color-stone-gray) !important;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--ion-color-stone-gray) !important;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--ion-color-stone-gray) !important;
    }
  }

  .mobile-list-item {
    background-color: var(--ion-color-deep-gray);
    border-top: 1px solid var(--ion-color-deep-gray);

    h4,
    p {
      color: var(--ion-color-stone-gray);
    }
  }

  /***** ion-modal Element General Class *****/

  /*** Ion Selectable Modal  Class ***/
  /* ionic-selectable-modal {
    ion-content::part(background) {
      background: var(--ion-color-deep-gray);
    }
  }

  ionic-selectable-modal {

    ion-content {
      background-color: var(--ion-color-deep-gray);
      color: var(--ion-color-stone-gray);

      ion-list.list-md {
        background-color: var(--ion-color-deep-gray);
        color: var(--ion-color-stone-gray);

        ion-item-group ion-item {
          background-color: var(--ion-color-deep-gray);
          color: var(--ion-color-stone-gray);
          border-bottom: 1px solid var(--ion-color-stone-gray);
        }

        ion-item-group ion-item::part(native) {
          background-color: var(--ion-color-deep-gray);
          color: var(--ion-color-stone-gray);
        }
      }
    }

    ion-footer {
        .toolbar-background {
          background-color: var(--ion-color-deep-gray) !important;
        }
    }

  } */

  /*** Ion Selectable Modal  Class ***/

  /**+ Clases adicionales para el modo dark ***/
  ion-content.content-color-custom::part(background) {
    background: var(--ion-color-light-black);
  }

  ion-content.content-color-custom::part(scroll) {
    color: var(--ion-color-stone-gray);
  }

  ion-content.content-color-custom {
    --background: var(--ion-color-light-black);
    --color: var(--ion-color-stone-gray);
  }

  .ion-border-custom-color {
    border: 1px solid var(--ion-color-stone-gray-inv);
  }

  .ion-border-top {
    border-top: 1px solid var(--ion-color-stone-gray-inv);
  }

  .ion-border-bottom {
    border-bottom: 1px solid var(--ion-color-stone-gray-inv);
  }

  .ion-border-start {
    border-left: 1px solid var(--ion-color-stone-gray-inv);
  }

  .ion-border-end {
    border-right: 1px solid var(--ion-color-stone-gray-inv);
  }

  .h-color-custom,
  .p-color-custom {
    color: var(--ion-color-stone-gray);
    --color: var(--ion-color-stone-gray);
  }

  .container-box-bg-color-custom {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);

    h3,
    p,
    a {
      color: var(--ion-color-stone-gray);
    }
  }

  ion-toolbar.toolbar-color-custom {
    --background: var(--ion-color-deep-gray);
    --color: var(--ion-color-stone-gray);

    ion-title {
      color: var(--ion-color-stone-gray);
      --color: var(--ion-color-stone-gray);
    }
  }

  img.navbar-logo-content {
    content: url('assets/imgs/logo/wordmark/Quantum-Wordmark-White.png');
  }

  img.btn-mobile-icon-menu {
    content: url('assets/imgs/icons/menu-w.png');
  }

  img.avatar-default-content {
    content: url('assets/imgs/logo/VPX-lockup/Quantum-VPX-Lockup-white.png');
  }

  ion-list.list-color-custom {
    background-color: var(--ion-color-deep-gray);
  }

  ion-list-header.list-header-color-custom {
    ion-label {
      color: var(--ion-color-gainsboro);
    }
  }

  ion-list-header.lb-list-header {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  ion-item-divider.item-divider-color-custom {
    --background: var(--ion-color-deep-gray);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);
  }

  ion-item.user-settings-header-list-item {
    --background: var(--ion-color-deep-gray);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);
  }

  ion-item.user-settings-list-item {
    --background: var(--ion-color-light-black);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);
  }

  ion-item.item-color-custom {
    --background: var(--ion-color-deep-gray);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);
  }

  ion-item.item-color-custom-inv {
    --background: var(--ion-color-light-black);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);
  }

  ion-label.label-color-custom,
  label.label-color-custom {
    color: var(--ion-color-stone-gray);
    --color: var(--ion-color-stone-gray);
  }

  ionic-selectable.item-selectable-color-custom {
    color: var(--ion-color-stone-gray);
  }

  ion-icon.icon-color-custom {
    color: var(--ion-color-stone-gray);
    --color: var(--ion-color-stone-gray);
  }

  ion-textarea.textarea-color-custom {
    border: 1px solid var(--ion-color-stone-gray-inv);
  }

  ion-searchbar.searchbar-color-custom {
    --background: var(--ion-color-deep-gray);
    --border-color: var(--ion-color-stone-gray-inv);
    --color: var(--ion-color-stone-gray);

    --placeholder-color: var(--ion-color-stone-gray);
    --icon-color: var(--ion-color-stone-gray);
    --clear-button-color: var(--ion-color-stone-gray);
    --cancel-button-color: var(--ion-color-stone-gray);
  }

  ion-checkbox.checkbox-color-custom {
    --checkmark-color: var(--ion-color-stone-gray);
    --background-checked: var(--ion-color-deep-gray);
  }

  ion-checkbox.checkbox-color-custom::part(container) {
    border: 2px solid var(--ion-color-stone-gray-inv);
  }

  ion-popover.navbar-filters-popover {
    .popover-viewport {
      background-color: var(--ion-color-deep-gray);

      .filter-label {
        color: var(--ion-color-stone-gray);
      }
    }
  }

  table.bsc-mat-table-custom {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  mat-header-cell.bsc-mat-header-custom {
    color: var(--ion-color-stone-gray);
  }

  mat-cell.bsc-mat-cell-custom {
    color: var(--ion-color-stone-gray);
  }

  mat-row.bsc-mat-row-custom {
    border-color: var(--ion-color-stone-gray-inv);
  }

  h2.subheader-table-indicator-data {
    color: #eff0f08a !important;
  }

  h2.table-content-data {
    color: #eff0f0de !important;
  }

  h2.header-table-input {
    color: var(--ion-color-stone-gray);
  }

  .card-bar-chart {
    background-color: var(--ion-color-deep-gray);
  }

  .fuse-card {
    color: var(--ion-color-stone-gray);
    background-color: var(--ion-color-deep-gray);
  }

  mat-row.statements-mat-row-custom,
  mat-header-row.statements-mat-header-custom {
    border-color: var(--ion-color-stone-gray-inv);
  }

  mat-cell.statements-mat-cell-custom,
  mat-header-cell.statements-mat-header-cell-custom {
    color: var(--ion-color-stone-gray);
  }

  ion-button.btn-unpressed-custom {
    background: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
    border: 1px solid var(--ion-color-stone-gray-inv);
  }

  mat-table.statements-mat-table-custom {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  .key-performance-indicators-fuse-card {
    color: var(--ion-color-stone-gray);
  }

  mat-table.performance-mat-table-custom {
    background-color: var(--ion-color-deep-gray);
    color: var(--ion-color-stone-gray);
  }

  mat-row.performance-mat-row-custom,
  mat-header-row.performance-mat-header-row-custom {
    border-color: var(--ion-color-stone-gray-inv);
  }

  mat-cell.performance-mat-cell-custom,
  mat-header-cell.performance-mat-header-cell-custom {
    color: var(--ion-color-stone-gray);
  }

  .key-performance-indicators-container {
    background-color: var(--ion-color-deep-gray);
  }

  ion-button.analysis {
    background: var(--ion-color-deep-gray) !important;
    color: var(--ion-color-stone-gray) !important;
    border: 1px solid var(--ion-color-stone-gray-inv) !important;
  }

  ion-button.btn-unpressed-custom {
    background: var(--ion-color-deep-gray) !important;
    color: var(--ion-color-stone-gray) !important;
    border: 1px solid var(--ion-color-stone-gray-inv) !important;
  }

  span.key-performance-indicators-title-h2,
  span.key-performance-indicators-title-h5 {
    color: var(--ion-color-stone-gray) !important;
  }

  .filter-selectable-list {
    background-color: var(--ion-color-deep-gray);
  }

  .categories {

    .dropdown {
      box-shadow: 0 1px 1px rgba(226, 221, 214, 0.10) !important;
      background-color: var(--ion-color-deep-gray) !important;
      color: var(--ion-color-stone-gray);
    }

    .dropdown-content li>ul {
      background-color: var(--ion-color-deep-gray);
      color: var(--ion-color-stone-gray);
    }
  }

  .category-modal-footer {
    background-color: var(--ion-color-light-black) !important;
  }

  ion-list.mobile-list-custom {
    h2 {
      &:first-child {
        color: var(--ion-color-stone-gray) !important;
      }
    }

    ion-item-divider {
      background-color: var(--ion-color-deep-gray);
      border-bottom: 1px solid var(--ion-color-stone-gray-inv) !important;

      &.mobile-list-header {
        h2 {
          &:first-child {
            color: var(--ion-color-deep-black) !important;
          }
        }
      }
    }
  }

  .modal-perfil,
  .modal-perfil-xs {

    h4,
    h2,
    h1 {
      color: var(--ion-color-tech-blue);
    }

    h3,
    h5,
    p {
      color: var(--ion-color-stone-gray) !important;
    }

    .box-blue {
      background-color: var(--ion-color-deep-gray) !important;
      box-shadow: -1px 5px 5px rgba(234, 221, 214, 0.10) !important;

      .divider-box {
        width: 100%;
        border: 0.5px solid var(--ion-color-stone-gray-inv);
        margin: 15px 0px 5px;
      }

      .table-perfil {
        table {
          tr {
            border-bottom: 1px solid var(--ion-color-stone-gray-inv) !important;

            td:first-child {
              color: var(--ion-color-stone-gray) !important;
            }

            td:last-child {
              color: var(--ion-color-stone-gray) !important;
            }
          }
        }
      }
    }
  }

  .modal-perfil-xs {
    .box-blue {
      background-color: var(--ion-color-deep-gray) !important;
      box-shadow: -1px 5px 5px rgba(234, 221, 214, 0.10) !important;
    }
  }

  .patio-container {
    .patio {
      .bahia-patio {
        background-color: var(--ion-color-deep-gray) !important;
      }
    }
  }

  .selected-bay-container {
    ion-button.unselect-button {
      --color: var(--ion-color-stone-gray) !important;
      color: var(--ion-color-stone-gray) !important;
      border: 1px solid var(--ion-color-stone-gray-inv) !important;
    }

    .fila-patio {
      .numero-fila-patio {
        color: var(--ion-color-stone-gray);
      }
    }
  }

  ion-label.ion-label-wheel-picker {
    color: var(--ion-color-stone-gray);
  }

  ion-datetime.full-wheel-picker {
    --background: rgb(33, 33, 33);
    --background-rgb: 33, 33, 33;
    --wheel-highlight-background: rgb(33, 33, 33);
    --wheel-fade-background-rgb: 33, 33, 33;
  }

  ion-datetime.modal-mobile-datetime {
    color: var(--ion-color-stone-gray);
    --background: rgb(33, 33, 33);
    --background-rgb: 33, 33, 33;
    --wheel-highlight-background: rgb(33, 33, 33);
    --wheel-fade-background-rgb: 33, 33, 33;
  }

  ion-datetime.full-wheel-picker::part(wheel-item),
  ion-datetime.modal-mobile-datetime::part(wheel-item) {
    color: var(--ion-color-stone-gray);
  }

  .m-current-consult-container {
    p {
      color: var(--ion-color-stone-gray) !important;
    }

    ul {

      >li.lb-current-consult {
        color: var(--ion-color-stone-gray) !important;
      }
    }
  }

  .squared-link {
    .squared-option {
      background-color: var(--ion-color-deep-gray) !important;
      color: var(--ion-color-stone-gray) !important;

      p {
        color: var(--ion-color-stone-gray) !important;
      }
    }

    .icon-circle {
      background-color: var(--ion-color-light-black) !important;
    }
  }

  .squared-link-shadow {
    .squared-option {
      box-shadow: -1px 0px 5px 5px rgba(226, 221, 214, 0.1) !important;
    }
  }

  p.lb-detail {
    color: var(--ion-color-stone-gray);
  }

  .mapLegendRastreo {
    color: var(--ion-color-stone-gray);
  }

  /* Material Design styles */
  ion-segment-button.md::part(native) {
    color: var(--ion-color-stone-gray);
  }

  .segment-button-checked.md::part(native) {
    color: var(--ion-color-aqua);
  }

  /* iOS styles */
  ion-segment-button.ios::part(native) {
    color: var(--ion-color-stone-gray);
  }

  .segment-button-checked.ios::part(native) {
    color: var(--ion-color-aqua);
  }

  .mobile-box-white {
    background: var(--ion-color-deep-gray);
    -webkit-box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.1), inset 0 1px 0 0 var(--ion-color-stone-gray);
    box-shadow: 0 1px 1px 0 rgba(29, 34, 41, 0.1), inset 0 1px 0 0 var(--ion-color-stone-gray);

    input {
      border: 1px solid var(--ion-color-stone-gray-inv);
    }
  }

  ion-select.select-input-color-custom {
    color: var(--ion-color-stone-gray);
    border: 1px solid var(--ion-color-stone-gray-inv);

  }

  ion-popover.select-popover {
    ion-list {
      background: var(--ion-color-deep-gray);
      color: var(--ion-color-stone-gray);
      box-shadow: 0 1px 1px rgba(29, 34, 41, 0.10);

      ion-list-header {
        background-color: var(--ion-color-deep-gray);
        color: var(--ion-color-stone-gray);
      }

      ion-radio-group {
        ion-item {
          --background: var(--ion-color-deep-gray);
          --border-color: var(--ion-color-stone-gray-inv);
          --color: var(--ion-color-stone-gray);
        }

        ion-item:has(.ion-focused) {
          // ion-item::part(native) {
            .item-native::after {
              color: var(--ion-color-deep-black) !important;
              background: var(--ion-color-tech-blue) !important;
              opacity: 1 !important;
            }
          // }
        }
      }
    }
  }

  .vin-ion-input {
    input {
      color: var(--ion-color-stone-gray);
    }
  }

  .searchbox-container {

    ion-input,
    ion-label {
      color: var(--ion-color-stone-gray) !important;
    }

    box-shadow: 0 1px 1px rgba(226, 221, 214, 0.1);
    background-color: var(--ion-color-deep-gray);
  }

  .filters-mobile-popup {
    background: var(--ion-color-stone-gray);

    .heading.category-modal-title {
      background-color: var(--ion-color-deep-gray);
    }

    .category-modal-footer {
      background-color: var(--ion-color-deep-gray);
    }

    p,
    a {
      color: var(--ion-color-stone-gray);
    }

    form {
      border: 1px solid var(--ion-color-stone-gray);

      .total-item {
        color: var(--ion-color-stone-gray);
      }
    }

    .filters a,
    &.filtr .moduloSub a {
      color: var(--ion-color-stone-gray);
    }

    a.btn {
      &.btn-aqua-squared {
        color: var(--ion-color-stone-gray);
      }
    }

    .calificacion {
      .filter-items .subFiltros {
        border-bottom: 1px solid var(--ion-color-stone-gray);
      }
    }
  }

  .filters-mobile-popup.filtr .moduloSub {
    background: var(--ion-color-deep-gray);
  }

  .filters-mobile-popup .filters .filterApplied {
    border-bottom: 1px solid var(--ion-color-stone-gray);
  }

  ion-text {

    h3,
    p {
      color: var(--ion-color-stone-gray);
    }
  }

  p.p-label-xs {
    color: var(--ion-color-stone-gray);
  }

  select.custom-select {
    color: var(--ion-color-stone-gray);
  }

  ion-label.lb-status {
    color: var(--ion-color-stone-gray);
  }

  .qr-card {
    background-color: var(--ion-color-deep-gray);
  }

  .qrInputText {
    color: var(--ion-color-stone-gray);
  }

  ion-row.information-container {
    background-color: var(--ion-color-deep-gray); // rgba(0, 0, 0, 0.1);

    .information-text {

      .information-data {
        color: var(--ion-color-stone-gray) !important;
      }
    }
  }

  ion-col.m-col-filters {
    ion-select::part(text) {
      color: var(--ion-color-stone-gray) !important;
    }
  }

  .styleWeekLabel {
    color: var(--ion-color-stone-gray);
  }

  /**+ FIN - Clases adicionales para el modo dark ***/

  /* E Charts class */

  /* div.graph-pie-over-box {
    border-bottom: 1px solid var(--ion-color-stone-gray-inv);
    background-color: var(--ion-color-deep-gray);
    font-family: "Alliance-Regular";
    color: var(--ion-color-stone-gray);
    font-size: 18px;
    padding-bottom: 7px;
    margin-bottom: 7px
  } */
}

/************************************************** FIN - Dark Mode Class **************************************************/