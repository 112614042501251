$color_mercury_approx: #e6e6e6;
$white: #fff;
$black_10: rgba(0,0,0,0.1);
$color_celeste_approx: #ccc;
$color_curious_blue_approx: #2aa1c0;
$color_atoll_approx: #0e647d;
$color_concord_approx: #7b7b7b;
$black: #000;

.control-group {
	display       : inline-block;
	vertical-align: top;
	background    : $white;
	text-align    : left;
	padding       : 30px;
	width         : 200px;
	height        : 210px;
	margin        : 10px;
}

.control {
	display      : block;
	position     : relative;
	padding-left : 30px;
	margin-bottom: 15px;
	cursor       : pointer;

	input {
		position: absolute;
		z-index : -1;
		opacity : 0;

		&:checked {
			~ .control__indicator {
				background: transparent;

				&:after {
					display: block;
				}
			}

			&:focus ~ .control__indicator {
				background: transparent;
			}
		}

		&:focus ~ .control__indicator {
			background: transparent;
		}

		&:disabled ~ .control__indicator {
			background    : transparent;
			opacity       : .6;
			pointer-events: none;
		}
	}

	&:hover input {
		&:not([disabled]):checked ~ .control__indicator {
			background: transparent;
		}
	}
}

.control__indicator {
	position     : absolute;
	top          : 2px;
	left         : 0;
	height       : 15px;
	width        : 15px;
	border       : solid 1px $aqua;
	border-radius: 2px;

	&:after {
		content : '';
		position: absolute;
		display : none;
	}
}

.control--checkbox {
	.control__indicator:after {
		left        : 6px;
		top         : 2px;
		width       : 3px;
		height      : 8px;
		border      : solid #fff;
		border-width: 0 1px 1px 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform   : rotate(45deg);
	}

	input:disabled ~ .control__indicator:after {
		border-color: $aqua;
	}
}

@media (min-width: 320px) and (max-width: 767px) {
	.control {
		margin-bottom: 0;
	}
}

.checkbox-md {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;

  .checkbox-icon {
    border: solid 1px #36C1F0;
    border-radius: 2px;
    background-color: transparent;
  }

  .checkbox-checked {
    .checkbox-inner {
      left: 5px;
      top: 1px;
    }
  }
}

