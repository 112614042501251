.absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.vertical-center  {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*
.col-centered {
  float: none;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}
*/

.full-width {
  width: 100%;
}

.float-right {
  float: right;
}

.icon-aqua {
  fill: $aqua;
}

.flex-box {
  display:flex;
  flex-wrap:wrap;
}

.mt-14 {
  margin-top:14px;
}

.mt-30 {
  margin-top:30px;
}

.mt-40 {
  margin-top:40px;
}

.transition {
	-webkit-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
	-moz-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
	-ms-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -o-transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: all 300ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

// .input-underline input {
  // padding-bottom: 7px !important;
  // color: $light-grey !important;
  // border-bottom: solid 1px $grey !important;
// 
  // &:focus {
    // color: $aqua !important;
    // border-bottom: solid 1px $aqua !important;
// 
    // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      // color: $aqua !important;
    // }
    // &::-moz-placeholder { /* Firefox 19+ */
      // color: $aqua !important;
    // }
    // &:-ms-input-placeholder { /* IE 10+ */
      // color: $aqua !important;
    // }
    // &:-moz-placeholder { /* Firefox 18- */
      // color: $aqua !important;
    // }
  // }
// 
  // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    // color: $light-grey !important;
  // }
  // &::-moz-placeholder { /* Firefox 19+ */
    // color: $light-grey !important;
  // }
  // &:-ms-input-placeholder { /* IE 10+ */
    // color: $light-grey !important;
  // }
  // &:-moz-placeholder { /* Firefox 18- */
    // color: $light-grey !important;
  // }
// }

.status-green {
  // background-color: #67F2B9 !important;
  background-color: #14e18c !important;
}

.status-yellow {
  background-color: #FFB435 !important;
}

.status-black{
  background-color: #5B5E62 !important;
}

.status-red{
  background-color: #F03652 !important;
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2017-12-18 17:8:1
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
/* ---------
 * Utilities taken from Bootstrap
 * -Marcela Ramirez-
 * ---------*/

@media (max-width:767px) {
  .hidden-xs {
    display:none !important
  }
}

@media (max-width:820px) {
  .hidden-md {
    display:none !important
  }
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display:none !important
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display:none !important
}

@media (max-width:767px) {
  .visible-xs {
    display:block !important
  }
  table.visible-xs {
    display:table !important
  }
  tr.visible-xs {
    display:table-row !important
  }
  th.visible-xs,
  td.visible-xs {
    display:table-cell !important
  }
}

@media (max-width:767px) {
  .visible-xs-block {
    display:block !important
  }
}

@media (max-width:767px) {
  .visible-xs-inline {
    display:inline !important
  }
}

@media (max-width:767px) {
  .visible-xs-inline-block {
    display:inline-block !important
  }
}
